import { Route, Routes } from "react-router-dom";
import AdminHome from "../../pages/Admin/AdminHome/AdminHome";
import Branch from "../../pages/Admin/Customer/Branch";
import Customer from "../../pages/Admin/Customer/Customer";
import Requests from "../../pages/Admin/Requests/Requests";
import UserReports from "../../pages/Admin/UserReports/UserReports";
import Profile from "../../pages/Profile/Profile";
import UpdatePass from "../../pages/UpdatePass/UpdatePass";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import AssignDriver from "../../pages/Admin/AssignDriver/AssignDriver";
import Reports from "../../pages/Admin/UserReports/Reports";

function AdminRoutes(props) {
  return (
    <div>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<AdminHome />} />
        <Route exact path="/customer" element={<Customer />} />
        <Route exact path="/customer/:customerId" element={<Branch />} />
        <Route exact path="/assign" element={<AssignDriver />} />
        <Route exact path="/requests" element={<Requests />} />
        <Route exact path="/user-report/:driverId" element={<UserReports />} />
        <Route exact path="/reports" element={<Reports />} />
        <Route exact path="/profile" element={<Profile />} />
        <Route exact path="/change-password" element={<UpdatePass />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default AdminRoutes;
