import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import api from "../../../api/api";

const AddBranch = () => {
  const [branchName, setBranchName] = useState("");
  const [branchPhone, setBranchPhone] = useState("");
  const [branchAddress, setBranchAddress] = useState("");
  const [loading, setLoading] = useState(false);

  const { customerId } = useParams();

  const { token } = useSelector((state) => state.user);

  const handleAddBranch = (e) => {
    e.preventDefault();

    if (
      branchName.trim() === "" ||
      branchPhone.trim() === "" ||
      branchAddress.trim() === ""
    ) {
      toast.error("Fill All Fields");
      return;
    }

    const data = [
      {
        BranchName: branchName,
        BranchContact: branchPhone,
        Address: branchAddress,
      },
    ];
    // console.log(data);

    setLoading(true);

    api({
      method: "POST",
      url: `/api/branch_add/${customerId}`,
      headers: {
        Authorization: `Token ${token}`,
      },
      data: data,
    })
      .then((res) => {
        // console.log("/api/branch_add/", res.data);
        toast.success("Branch Added Successfully");
        window.location.reload();
      })
      .catch((err) => {
        console.log(err.response.data);
        toast.error(err?.response?.data?.detail || "Failed To Add Branch");
      })
      .finally(() => setLoading(false));
  };
  return (
    <form
      // className="flex flex-col bg-white rounded shadow-lg p-7 mt-2 w-full md:w-[60%] lg:w-[30%]"
      onSubmit={handleAddBranch}
    >
      <h1 className="font-bold text-2xl mb-4 text-center">Add New Branch</h1>
      <label className="font-semibold text-xs mb-1" htmlFor="branchNameField">
        Branch Name
      </label>
      <input
        id="branchNameField"
        name="branchNameField"
        type="text"
        placeholder="Type here"
        className="input w-full bg-gray-100"
        value={branchName}
        onChange={(e) => setBranchName(e.target.value)}
        required
      />
      <label
        className="font-semibold text-xs mt-3 mb-1"
        htmlFor="branchPhoneField"
      >
        Phone
      </label>
      <input
        id="branchPhoneField"
        name="branchPhoneField"
        type="text"
        placeholder="Type here"
        className="input w-full bg-gray-100"
        value={branchPhone}
        onChange={(e) => setBranchPhone(e.target.value)}
        required
      />
      <label
        className="font-semibold text-xs mt-3 mb-1"
        htmlFor="branchAddressField"
      >
        Address
      </label>
      <input
        id="branchAddressField"
        name="branchAddressField"
        type="text"
        placeholder="Type here"
        className="input w-full bg-gray-100"
        value={branchAddress}
        onChange={(e) => setBranchAddress(e.target.value)}
        required
      />

      {!loading ? (
        <button
          type="submit"
          disabled={loading}
          className="flex items-center justify-center h-12 px-6 w-full bg-blue-600 mt-8 rounded font-semibold text-sm text-blue-100 hover:bg-blue-700"
        >
          Add Branch
        </button>
      ) : (
        <button
          disabled={loading}
          className="btn flex items-center justify-center h-12 px-6 w-full bg-blue-600 mt-8 rounded font-semibold text-sm text-blue-100 loading"
        >
          Loading...
        </button>
      )}
    </form>
  );
};

export default AddBranch;
