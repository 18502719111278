import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import Select from "react-select";
import api from "../../../api/api";
import DatePicker from "react-datepicker";
import moment from "moment";

import "react-datepicker/dist/react-datepicker.css";

const UpdateForm = ({ assignData, loading, setLoading }) => {
  const [date, setDate] = useState(new Date(assignData?.Date));
  const { token } = useSelector((state) => state.user);

  const handleRescheduleAssign = (e) => {
    e.preventDefault();
    if (!date) {
      toast.error("Select All Fields");
      return;
    }

    setLoading(true);
    const formattedDate = moment(date).toISOString(true).split("T")[0];

    const data = {
      Date: formattedDate,
    };

    console.log(data);
    api({
      method: "PUT",
      url: `/api/reschedule_package_assign/${assignData.id}`,
      headers: {
        Authorization: `Token ${token}`,
      },
      data: data,
    })
      .then((res) => {
        // console.log("reschedule_package_assign", res.data);
        toast.success("Package Rescheduled successfully");
        window.location.reload();
      })
      .catch((err) => {
        console.log(err.response.data);
        toast.error(err.response?.data?.detail || "Failed To Assign Driver");
      })
      .finally(() => setLoading(true));
  };

  return (
    <form
      // className="flex flex-col bg-white rounded shadow-lg p-7 mt-2 w-full md:w-[60%] lg:w-[30%]"
      onSubmit={handleRescheduleAssign}
    >
      <h1 className="font-bold text-2xl mb-4 text-center">Reschedule</h1>
      <label className="font-semibold text-xs mb-1" htmlFor="branchNameField">
        Select Driver
      </label>
      <Select
        className="basic-single"
        classNamePrefix="select"
        isSearchable
        name="driver"
        options={[
          { label: assignData.DriverName, value: assignData.DriverName },
        ]}
        value={{ label: assignData.DriverName, value: assignData.DriverName }}
        isDisabled={true}
      />
      <label
        className="font-semibold text-xs mt-3 mb-1"
        htmlFor="branchPhoneField"
      >
        Select Customer
      </label>
      <Select
        className="basic-single"
        classNamePrefix="select"
        isSearchable
        name="customer"
        isDisabled={true}
        options={[
          { label: assignData.CustomerName, value: assignData.CustomerName },
        ]}
        value={{
          label: assignData.CustomerName,
          value: assignData.CustomerName,
        }}
      />
      <label
        className="font-semibold text-xs mt-3 mb-1"
        htmlFor="branchAddressField"
      >
        Select Branch
      </label>
      <Select
        className="basic-single"
        classNamePrefix="select"
        isSearchable
        name="customer"
        isDisabled={true}
        options={[
          { label: assignData?.BranchName, value: assignData?.BranchName },
        ]}
        value={{ label: assignData?.BranchName, value: assignData?.BranchName }}
        required
      />
      <label
        className="font-semibold text-xs mt-3 mb-1"
        htmlFor="branchAddressField"
      >
        Date
      </label>
      <DatePicker
        className="input w-full bg-gray-100 z-50"
        selected={date}
        value={date}
        onChange={(date) => setDate(date)}
        minDate={new Date()}
        popperPlacement="top"
      />

      {!loading ? (
        <button
          type="submit"
          disabled={loading}
          className="flex items-center justify-center h-12 px-6 w-full bg-blue-600 mt-8 rounded font-semibold text-sm text-blue-100 hover:bg-blue-700"
        >
          Reschedule
        </button>
      ) : (
        <button
          disabled={loading}
          className="btn flex items-center justify-center h-12 px-6 w-full bg-blue-600 mt-8 rounded font-semibold text-sm text-blue-100 loading"
        >
          Loading...
        </button>
      )}
    </form>
  );
};

export default UpdateForm;
