import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import api from "../../../api/api";
import { IoCloseCircle } from "react-icons/io5";

const ReqCard = ({ reqData }) => {
  const [openModal, setOpenModal] = useState(false);
  const { token } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);

  const QuesArr = JSON.parse(reqData.vehicle_detail[0].Confirmation);

  const acceptRequest = () => {
    setLoading(true);
    api({
      method: "PUT",
      url: `/api/driver_status/${reqData.id}`,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => {
        console.log(res.data);
        toast.success("Request Accepted Successfully");
        window.location.reload();
      })
      .catch((err) => {
        console.log(err.response.data);
        toast.error("Failed To Accept Request");
      })
      .finally(() => setLoading(false));
  };

  const declineRequest = () => {
    setLoading(true);
    api({
      method: "DELETE",
      url: `/api/driver_request_decline/${reqData.id}`,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => {
        console.log(res.data);
        toast.success("Request Declied Successfully");
        window.location.reload();
      })
      .catch((err) => {
        console.log(err.response.data);
        toast.error("Failed To Decline Request");
      })
      .finally(() => setLoading(false));
  };
  return (
    <>
      <div className="flex flex-col p-4 mt-5 w-full md:w-[60%] lg:w-[30%] shadow-md rounded border-2 border-gray-200">
        <div>
          <h3 className="text-center font-semibold text-xl">{`${reqData?.first_name} ${reqData?.last_name}`}</h3>
        </div>
        <div className="divider m-0"></div>
        <div>
          <p className="font-semibold mb-1">Email: {reqData?.email}</p>
          <p className="font-semibold mb-1">Phone: {reqData?.Phone}</p>
          <p className="font-semibold mb-1">
            Vehicle: {reqData?.vehicle_detail[0]?.Vehicle}
          </p>
        </div>
        <div className="divider m-0"></div>
        <div className="flex justify-center gap-4">
          <button
            className="btn btn-primary"
            disabled={loading}
            onClick={acceptRequest}
          >
            Accept
          </button>
          <button
            className="btn btn-primary"
            disabled={loading}
            onClick={declineRequest}
          >
            Reject
          </button>
          <button
            onClick={(e) => setOpenModal(!openModal)}
            htmlFor="my-modal"
            className="btn btn-primary"
          >
            More Details
          </button>
        </div>
      </div>

      {/* Modal  */}
      <input
        type="checkbox"
        id="my-modal"
        className="modal-toggle"
        checked={openModal}
        readOnly
      />
      <div
        key={`modal-${reqData?.id}`}
        className="modal"
        onClick={(e) => setOpenModal(!openModal)}
      >
        <div className="modal-box relative overflow-scroll rounded-none">
          <div className="modal-action absolute -top-3 right-2 text-red-600 cursor-pointer">
            <IoCloseCircle
              htmlFor="my-modal"
              size={28}
              onClick={(e) => setOpenModal(!openModal)}
            />
            {/* <button
              onClick={(e) => setOpenModal(!openModal)}
              htmlFor="my-modal"
              className="p-4 rounded-full bg-blue-400"
            >
              X
            </button> */}
          </div>
          <div>
            <p className="font-semibold mb-1">Licence:</p>
          </div>
          <div className="box-border mb-2">
            <img
              className="w-full h-full "
              src={reqData?.vehicle_detail[0]?.Licence}
              alt="license"
            />
          </div>
          <div className="divider m-0"></div>
          {QuesArr &&
            QuesArr.map((q, index) => (
              <div key={index}>
                {" "}
                <div className="mb-2 flex justify-between items-center gap-1">
                  <p className="font-semibold">{q?.que}</p>
                  <p className="font-semibold">{q?.ans}</p>
                </div>
                <div className="divider m-0"></div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default ReqCard;
