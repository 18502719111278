import React, { useEffect } from "react";
import { toast, Toaster, useToasterStore } from "react-hot-toast";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";

import "./App.css";
import AdminRoutes from "./components/AdminRoutes/AdminRoutes";
// import Footer from "./components/Footer/Footer";
import { Header } from "./components/Navbar/Header";
import PrivateRoutes from "./components/PrivateRoute/PrivateRoutes";
import LandingPage from "./pages/LandingPage/LandingPage";
import Login from "./pages/Login/Login";
// import MaintenanceMode from "./pages/MaintenanceMode/MaintenanceMode";
import ResetConfirm from "./pages/ResetPass/ResetConfirm";
import ResetPass from "./pages/ResetPass/ResetPass";
import Signup from "./pages/Signup/Signup";

function App() {
  const { isLoggedIn, role } = useSelector((state) => state.user);
  // let location = useLocation();

  // console.log(location.pathname);

  const TOAST_LIMIT = 1;
  const { toasts } = useToasterStore();

  // Enforce Limit
  useEffect(() => {
    toasts
      .filter((t) => t.visible) // Only consider visible toasts
      .filter((_, i) => i >= TOAST_LIMIT) // Is toast index over limit
      .forEach((t) => toast.dismiss(t.id)); // Dismiss – Use toast.remove(t.id) removal without animation
  }, [toasts]);

  return (
    <>
      {isLoggedIn ? (
        <>{role === "Admin" ? <AdminRoutes /> : <PrivateRoutes />}</>
      ) : (
        <>
          {/* {location?.pathname !== "/" && <Header />} */}
          <Header />
          <Routes>
            {/* <Route exact path="/" element={<MaintenanceMode />} /> */}
            <Route exact path="/" element={<LandingPage />} />
            <Route exact path="/landing" element={<LandingPage />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/signup" element={<Signup />} />
            <Route exact path="/reset-password" element={<ResetPass />} />
            <Route
              exact
              path="/reset-password/confirm"
              element={<ResetConfirm />}
            />
            <Route exact path="*" element={<Navigate to="/" />} />
          </Routes>
        </>
      )}
      {/* <Footer /> */}
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        }}
      />
    </>
  );
}

export default App;
