import React from "react";
import { Link, useLocation } from "react-router-dom";

export const Header = () => {
  const { pathname } = useLocation();

  return (
    <header className="lg:px-16 px-6 bg-slate-800 flex flex-wrap items-center lg:py-0 py-4 sticky top-0 z-20">
      <div
        className={`flex-1 flex ${
          !pathname.includes("/login") && !pathname.includes("/signup")
            ? "justify-between"
            : "justify-center"
        } items-center`}
      >
        <Link
          to="/"
          className="font-semibold text-lg flex justify-center items-center gap-1 outline-none"
        >
          <img className="w-12 h-12" src="/images/enr.png" alt="EnR brand" />
          <div className="divider divider-horizontal mx-2 lg:mx-4 before:bg-gray-600 after:bg-gray-600 py-6"></div>
          <img
            className="w-32 md:w-40 py-2"
            src="/images/wheelz_logo2.png"
            // src="/images/wheelz_logo.png"
            alt="EnR Wheelz"
          />
        </Link>
      </div>

      {!pathname.includes("/login") && !pathname.includes("/signup") && (
        <>
          <label
            htmlFor="menu-toggle"
            className="pointer-cursor lg:hidden block"
          >
            <svg
              className="fill-current text-white"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
            >
              <title>menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
            </svg>
          </label>
          <input className="hidden" type="checkbox" id="menu-toggle" />

          <div
            className="hidden lg:flex lg:items-center lg:w-auto w-full"
            id="menu"
          >
            <nav>
              <ul className="lg:flex items-center justify-between text-base text-white pt-4 lg:pt-0 gap-2">
                <li>
                  <Link
                    to="/signup"
                    className="my-2 lg:my-0 px-6 py-2 rounded-xl block border-2 border-[#5EA11D] bg-[#0F2438]"
                  >
                    Register
                  </Link>
                </li>
                <li>
                  <Link
                    to="/login"
                    className="my-2 lg:my-0 px-6 py-2 rounded-xl block border-transparent bg-[#5EA11D] text-white"
                  >
                    Login
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </>
      )}
    </header>
  );
};
