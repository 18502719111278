import React from "react";

const FeaturesSection = () => {
  return (
    <section className="hidden lg:flex justify-around items-center p-4 flex-wrap">
      <div className="flex items-center gap-2 p-2">
        <img src="/images/hand-icon.png" alt="Handshake" />
        <p>Friendly Drivers</p>
      </div>
      <div className="flex items-center gap-2 p-2">
        <img src="/images/money-bag.png" alt="Moneybag" />
        <p>Friendly Drivers</p>
      </div>
      <div className="flex items-center gap-2 p-2">
        <img src="/images/map.png" alt="map" />
        <p>Friendly Drivers</p>
      </div>
    </section>
  );
};

export default FeaturesSection;
