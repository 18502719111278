import React, { useLayoutEffect, useState } from "react";
import AddForm from "./AddForm";
import CustomerTable from "./CustomerTable";
import * as xlsx from "xlsx";
import api from "../../../api/api";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { IoCloseCircle } from "react-icons/io5";

const Customer = () => {
  const [loading, setLoading] = useState(false);
  const [batchArr, setBatchArr] = useState("");
  const [openModal1, setOpenModal1] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);

  const { token } = useSelector((state) => state.user);

  const handleBatchUpload = (e) => {
    e.preventDefault();

    if (!Array.isArray(batchArr) || batchArr?.length === 0) {
      toast.error("Please Select File");
      return;
    }

    setLoading(true);

    api({
      method: "POST",
      url: `/api/customer_bulk_add`,
      headers: {
        Authorization: `Token ${token}`,
      },
      data: batchArr,
    })
      .then((res) => {
        console.log("/api/customer_bulk_add", res.data);
        toast.success("Added Successfully");
        window.location.reload();
      })
      .catch((err) => {
        console.log("err: ", err?.response?.data);
        // For Fixing Dynamic Errors generated by django sql
        const errStr = [];
        if (Array.isArray(err?.response?.data)) {
          for (const data of Object.entries(err?.response?.data[0])) {
            if (Array.isArray(data[1])) {
              errStr.push(`${data[0]} Error : ${data[1][0]}`);
            } else {
              errStr.push(data[1]);
            }
          }

          toast.error(errStr.join("\n") || "Failed To Upload File");
        } else {
          toast.error(err?.response?.data?.detail || "Failed To Upload File");
        }
      })
      .finally(() => setLoading(false));
  };

  const readUploadFile = (e) => {
    e.preventDefault();
    var validExts = [".xlsx", ".xls", ".csv"];
    var fileExt = e.target.value;
    fileExt = fileExt.substring(fileExt.lastIndexOf("."));
    if (validExts.indexOf(fileExt) < 0) {
      toast.error(
        "Invalid File Selected, Valid Files are of " +
          validExts.toString() +
          " Types."
      );
    } else {
      if (e.target.files) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = xlsx.read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const json = xlsx.utils.sheet_to_json(worksheet);
          const columnsArray = xlsx.utils.sheet_to_json(worksheet, {
            header: 1,
          })[0];
          const tempArr = [];
          const templateHeaders = [
            "Customer Number",
            "Customer Name",
            "Customer Phone",
            "Branch",
            "Branch Phone",
            "Branch Address",
          ];

          const intersection = templateHeaders.every((item) =>
            columnsArray.includes(item)
          );

          console.log("headers: ", intersection);
          if (intersection) {
            for (let i = 0; i < json.length; i++) {
              // console.log("json[i]", json[i]);
              if (
                tempArr.some(
                  (val) => val["customerId"] === json[i]["Customer Number"]
                )
              ) {
                // console.log(json[i]["Customer Number"]);
                tempArr[
                  tempArr.findIndex(
                    (dat) => dat.customerId === json[i]["Customer Number"]
                  )
                ].branch.push({
                  BranchName: json[i]["Branch"],
                  BranchContact: json[i]["Branch Phone"],
                  Address: json[i]["Branch Address"],
                });
              } else {
                tempArr.push({
                  customerId: json[i]["Customer Number"],
                  Name: json[i]["Customer Name"],
                  Contact: json[i]["Customer Phone"],
                  branch: [
                    {
                      BranchName: json[i]["Branch"],
                      BranchContact: json[i]["Branch Phone"],
                      Address: json[i]["Branch Address"],
                    },
                  ],
                });
              }
            }
            // console.log(json);
            console.log(tempArr);
            setBatchArr(tempArr);
          } else {
            toast.error("Template not valid");
            return;
          }
        };
        reader.readAsArrayBuffer(e.target.files[0]);
      }
    }
  };

  useLayoutEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <section className="flex flex-col items-center justify-center w-screen min-h-[77vh] text-gray-700 border-2 border-slate-200 py-2">
      <h3 className="text-center text-xl font-semibold ">Add Customer</h3>
      <div className="flex justify-center bg-white w-full md:w-[80%] lg:w-[40%] gap-2">
        <button
          onClick={(e) => setOpenModal1(!openModal1)}
          className="flex items-center justify-center h-12  px-6 w-full bg-blue-600 mt-4 rounded font-semibold text-sm text-blue-100 hover:bg-blue-700"
        >
          Batch Upload
        </button>
        <button
          onClick={(e) => setOpenModal2(!openModal2)}
          className="flex items-center justify-center h-12 px-6 w-full bg-blue-600 mt-4 rounded font-semibold text-sm text-blue-100 hover:bg-blue-700"
        >
          Add Single
        </button>
      </div>

      {/* <AddForm /> */}
      {/* <h3 className="text-center text-xl font-semibold mt-6 mb-4">
        Customer List
      </h3> */}
      <CustomerTable />

      {/* Modal 1 */}
      <input
        type="checkbox"
        id="my-modal1"
        className="modal-toggle"
        checked={openModal1}
        readOnly
      />
      <div key={`modal-${1}`} className="modal">
        <div className="modal-box relative overflow-scroll rounded-none p-4">
          <div className="modal-action absolute -top-3 right-2 text-red-600 cursor-pointer">
            <IoCloseCircle
              htmlFor="my-modal1"
              size={28}
              onClick={(e) => {
                setOpenModal1(!openModal1);
                setBatchArr([]);
              }}
            />
            {/* <button
              onClick={(e) => setOpenModal(!openModal)}
              htmlFor="my-modal"
              className="p-4 rounded-full bg-blue-400"
            >
              X
            </button> */}
          </div>
          <div className="">
            <a
              href="/files/template.xlsx"
              download
              className="flex items-center justify-center h-12 px-6 w-full font-semibold text-blue-600 uppercase"
            >
              Download Template
            </a>
            <h3 className="text-center text-xl font-semibold mt-2 mb-4">
              Batch Upload
            </h3>
            <input
              className="file-input w-full bg-gray-100"
              type="file"
              name="upload"
              id="upload"
              accept=".xlsx, .xls .csv"
              onChange={readUploadFile}
            />

            {!loading ? (
              <button
                disabled={loading}
                onClick={handleBatchUpload}
                className="flex items-center justify-center h-12 px-6 w-full bg-blue-600 mt-4 rounded font-semibold text-sm text-blue-100 hover:bg-blue-700"
              >
                Submit
              </button>
            ) : (
              <button
                disabled={loading}
                className="btn flex items-center justify-center h-12 px-6 w-full bg-blue-600 mt-8 rounded font-semibold text-sm text-blue-100 loading"
              >
                Loading...
              </button>
            )}
          </div>
        </div>
      </div>

      {/* Modal 2 */}
      <input
        type="checkbox"
        id="my-modal2"
        className="modal-toggle"
        checked={openModal2}
        readOnly
      />
      <div key={`modal-${2}`} className="modal">
        <div className="modal-box relative overflow-scroll rounded-none p-4">
          <div className="modal-action absolute -top-3 right-2 text-red-600 cursor-pointer">
            <IoCloseCircle
              htmlFor="my-modal2"
              size={28}
              onClick={(e) => setOpenModal2(!openModal2)}
            />
            {/* <button
              onClick={(e) => setOpenModal(!openModal)}
              htmlFor="my-modal"
              className="p-4 rounded-full bg-blue-400"
            >
              X
            </button> */}
          </div>
          <AddForm />
        </div>
      </div>
    </section>
  );
};

export default Customer;
