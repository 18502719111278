import React, { useState, useLayoutEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-hot-toast";
import api from "../../api/api";

const queData = [
  {
    que: "I acknowledge that the information provided is correct",
    options: ["Yes", "No"],
    ans: "",
  },
  {
    que: "I have a full UK driver's license",
    options: ["Yes", "No"],
    ans: "",
  },
  {
    que: "My vehicle is taxed",
    options: ["Yes", "No"],
    ans: "",
  },
  {
    que: "My vehicle has a valid MOT",
    options: ["Yes", "No"],
    ans: "",
  },
  {
    que: "I am sober and not under the influence of alcohol / drugs",
    options: ["Yes", "No"],
    ans: "",
  },
  {
    que: "I am insured for Goods in Transit and Public Liability Insurance",
    options: ["Yes", "No"],
    ans: "",
  },
  {
    que: "I have checked my vehicle for compliance",
    options: ["Yes", "No"],
    ans: "",
  },
];

const Signup = () => {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [vehicle, setVehicle] = useState("");
  const [fuel, setFuel] = useState("");
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [license, setLicense] = useState("");
  const [profilePic, setProfilePic] = useState("");
  const [password, setPassword] = useState("");
  const [conpassword, setConpassword] = useState("");
  const [loading, setLoading] = useState(false);

  const [que, setQue] = useState(queData);

  const navigate = useNavigate();

  const handleSignup = (e) => {
    e.preventDefault();
    if (
      fname === "" ||
      lname === "" ||
      email === "" ||
      phone === "" ||
      vehicle === "" ||
      fuel === "" ||
      vehicleNumber === "" ||
      password === "" ||
      profilePic === "" ||
      license === ""
    ) {
      toast.error("Fill All Fields");
      return;
    }
    if (password !== conpassword) {
      toast.error("Password And Confirm Password Are Not Matching");
      return;
    }
    if (phone.length < 10) {
      toast.error("Enter Valid Phone Number");
      return;
    }
    var isAnyQueNotAnswered = que.some(function (ele) {
      return ele.ans === "";
    });

    if (isAnyQueNotAnswered) {
      return toast.error("Select All Answers");
    }

    setLoading(true);

    console.log("signup: ", {
      profilePic,
      fname,
      lname,
      email,
      phone: `+${phone}`,
      vehicle,
      fuel,
      license,
      password,
      Confirmation: JSON.stringify(que),
    });
    // const config = {
    //   headers: {
    //     "content-type": "multipart/form-data",
    //   },
    // };

    const formData = new FormData();
    formData.append("email", email);
    formData.append("first_name", fname);
    formData.append("last_name", lname);
    formData.append("Phone", `+${phone}`);
    formData.append("password", password);
    formData.append("ProfilePic", profilePic);
    formData.append("Vehicle", vehicle);
    formData.append("Licence", license);
    formData.append("VehicleNumber", vehicleNumber);
    formData.append("FuelType", fuel);
    formData.append("Confirmation", JSON.stringify(que));

    // console.log("signup formdata, ", formData.getAll());

    api({
      method: "POST",
      url: "/api/register_driver",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        console.log(res.data);
        toast.success(
          "Request has been sent to admin wait for admin to confirm your request."
        );
        navigate("/");
      })
      .catch((err) => {
        console.log(err.response);
        toast.error(err?.response?.data?.detail || "Failed to create account");
      })
      .finally(() => setLoading(false));
  };

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 justify-center items-center w-screen lg:h-[88vh] py-4">
      {/* <div className="lg:hidden flex-1 flex justify-center items-center py-2">
        <Link
          to="/"
          className="font-semibold text-lg flex justify-center items-center gap-1"
        >
          <img className="w-12 h-12" src="/images/enr.png" alt="EnR brand" />
          <div className="divider divider-horizontal mx-2 lg:mx-4 before:bg-gray-300 after:bg-gray-300 py-6"></div>
          <img
            className="w-32 md:w-40"
            src="/images/Wheelz_crop.jpg"
            alt="EnR Wheelz"
          />
        </Link>
      </div> */}
      <section className="flex justify-center items-center py-2">
        <img
          className="w-3/4"
          src="/images/delivery_img.png"
          alt="delivery_img"
        />
      </section>
      <section className="flex flex-col items-center lg:h-[88vh] overflow-y-scroll">
        <div className="mt-4">
          <h1 className="font-bold text-2xl text-center">Branded WheelzPro</h1>
          <p className="mt-2 text-center">
            We just need a few basic details to get you started
          </p>
        </div>
        <form
          className="flex flex-col bg-white p-7 mt-4 mb-4"
          onSubmit={handleSignup}
        >
          <div className="mb-3">
            <label
              className="font-semibold text-xs mb-1"
              htmlFor="profilepicField"
            >
              Upload Profile Pic
            </label>
            <input
              type="file"
              id="profilepicField"
              name="profilepicField"
              className="file-input w-full bg-gray-100"
              accept="image/*"
              onChange={(e) => {
                const file = e.target.files[0];

                if (file) {
                  if (file?.type.split("/")[0] === "image") {
                    setProfilePic(e.target.files[0]);
                  } else {
                    toast.error("Only images are allowed");
                  }
                }
              }}
              required
            />
          </div>
          <div className="mb-3">
            <label
              className="w-full font-semibold text-xs mb-1"
              htmlFor="firstnameField"
            >
              First Name
            </label>
            <input
              type="text"
              placeholder="Type here"
              className="input w-full bg-gray-100"
              value={fname}
              id="firstnameField"
              name="firstnameField"
              onChange={(e) => setFname(e.target.value)}
              autoComplete=""
              required
            />
          </div>
          <div className="mb-3">
            <label
              className="w-full font-semibold text-xs mb-1"
              htmlFor="lastnameField"
            >
              Last Name
            </label>
            <input
              type="text"
              placeholder="Type here"
              className="input w-full bg-gray-100"
              value={lname}
              id="lastnameField"
              name="lastnameField"
              onChange={(e) => setLname(e.target.value)}
              autoComplete=""
              required
            />
          </div>
          <div className="mb-3">
            <label className="font-semibold text-xs mb-1" htmlFor="emailField">
              Email
            </label>
            <input
              type="email"
              id="emailField"
              name="emailField"
              placeholder="Type here"
              className="input w-full bg-gray-100"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="none"
              required
            />
          </div>
          <div className="mb-3">
            <label className="font-semibold text-xs mb-1" htmlFor="phoneField">
              Phone
            </label>
            <PhoneInput
              enableSearch
              country="gb"
              value={phone}
              onChange={(phone) => setPhone(phone)}
              containerclassName="h-12"
              containerStyle={{
                border: "none",
              }}
              inputclassName="input w-full bg-gray-100 border-none border-0"
              inputStyle={{
                border: "none",
                height: "3rem",
                backgroundColor: "rgb(243 244 246 / 1)",
                width: "100%",
              }}
              buttonclassName="h-12 border-none border-0"
              buttonStyle={{
                border: "none",
                backgroundColor: "rgb(229 231 235 / 1)",
              }}
              required
              inputProps={{ required: true }}
            />
          </div>
          <div className="mb-3">
            <label
              className="font-semibold text-xs mb-1"
              htmlFor="vehicleField"
            >
              Vehicle Type
            </label>
            <select
              className="select w-full bg-gray-100"
              id="vehicleField"
              name="vehicleField"
              value={vehicle}
              onChange={(e) => setVehicle(e.target.value)}
              required
            >
              <option disabled value="">
                Vehicle Type
              </option>
              <option value="Motorcycle">Motorcycle</option>
              <option value="Car">Car</option>
              <option value="Van">Van</option>
              <option value="Truck">Truck</option>
            </select>
          </div>

          <div className="mb-3">
            <label className="font-semibold text-xs mb-1" htmlFor="fuelField">
              Fuel Type
            </label>
            <select
              className="select w-full bg-gray-100"
              id="fuelField"
              name="fuelField"
              value={fuel}
              onChange={(e) => setFuel(e.target.value)}
              required
            >
              <option disabled value="">
                Fuel Type
              </option>
              <option value="Petrol">Petrol </option>
              <option value="Diesel">Diesel</option>
              <option value="Electric">Electric</option>
            </select>
          </div>

          <div className="mb-3">
            <label
              className="font-semibold text-xs mb-1"
              htmlFor="vehicleNumberField"
            >
              Vehicle Registed Number
            </label>
            <input
              type="text"
              id="vehicleNumberField"
              name="vehicleNumberField"
              placeholder="Type here"
              className="input w-full bg-gray-100"
              value={vehicleNumber}
              onChange={(e) => setVehicleNumber(e.target.value)}
              autoComplete="none"
              required
            />
          </div>
          <div className="mb-3">
            <label
              className="font-semibold text-xs mb-1"
              htmlFor="licenseField"
            >
              Upload license pic
            </label>
            <input
              type="file"
              id="licenseField"
              name="licenseField"
              className="file-input w-full bg-gray-100"
              accept="image/*"
              onChange={(e) => {
                const file = e.target.files[0];

                if (file) {
                  if (file?.type.split("/")[0] === "image") {
                    setLicense(e.target.files[0]);
                  } else {
                    toast.error("Only images are allowed");
                  }
                }
              }}
              required
            />
          </div>

          {queData.map((q, index) => (
            <div className="mb-3" key={`que-input-${index}`}>
              <label
                className="font-semibold text-xs mb-1"
                htmlFor={`radio-${index}`}
              >
                {q.que}
              </label>

              <div className="flex gap-2">
                {q.options.map((o, i) => (
                  <label
                    key={`q-input-${i}`}
                    className="label cursor-pointer flex gap-2"
                  >
                    <input
                      type="radio"
                      name={`radio-${index}`}
                      className="radio checked:bg-blue-500"
                      checked={que[index].ans === o}
                      onChange={(e) => {
                        const newArr = que.map((q, i) => {
                          if (index === i) return { ...q, ans: o };
                          return q;
                        });
                        setQue(newArr);
                        console.log(que);
                      }}
                    />
                    <span className="label-text">{o}</span>
                  </label>
                ))}
              </div>
            </div>
          ))}

          <div className="mb-3">
            <label
              className="font-semibold text-xs mb-1"
              htmlFor="passwordField"
            >
              Password
            </label>
            <input
              type="password"
              id="passwordField"
              name="passwordField"
              placeholder="Type here"
              className="input w-full bg-gray-100"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="new-password"
              required
            />
          </div>
          <div className="mb-5">
            <label
              className="font-semibold text-xs mb-1"
              htmlFor="conpasswordField"
            >
              Confirm Password
            </label>
            <input
              type="password"
              id="conpasswordField"
              name="conpasswordField"
              placeholder="Type here"
              className="input w-full bg-gray-100"
              value={conpassword}
              onChange={(e) => setConpassword(e.target.value)}
              autoComplete="new-password"
              required
            />
          </div>
          <div className="flex flex-col lg:flex-row items-center justify-center gap-2 mt-4">
            {!loading ? (
              <button
                type="submit"
                disabled={loading}
                className={`h-12 px-12 rounded-xl font-semibold text-sm text-white bg-slate-800 hover:bg-slate-800`}
              >
                Sign Up
              </button>
            ) : (
              <button className="btn loading">Submitting</button>
            )}
          </div>

          <div className="flex mt-6 justify-center text-xs gap-2">
            <span>Already have account?</span>
            <Link to="/login" className="text-blue-400 hover:text-blue-500">
              Login
            </Link>
          </div>
        </form>
      </section>
    </div>
  );
};

export default Signup;
