import React from "react";

const Banner = () => {
  return (
    <div
      className="hero min-h-[80vh] bg-center"
      style={{
        backgroundImage: `url("/images/Banner.png")`,
      }}
    >
      <div className="hero-overlay bg-opacity-30"></div>
      <div className="hero-content items-end text-center text-neutral-content">
        <div className="flex justify-center items-end">
          <h1 className="mb-5 text-5xl font-bold">
            Flexible Courier Jobs & Great Rates
          </h1>
          {/* <p className="mb-5">
            Provident cupiditate voluptatem et in. Quaerat fugiat ut assumenda
            excepturi exercitationem quasi. In deleniti eaque aut repudiandae et
            a id nisi.
          </p>
          <button className="btn btn-primary">Get Started</button> */}
        </div>
      </div>
    </div>
  );
};

export default Banner;
