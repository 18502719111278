import React, { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import api from "../../../api/api";
import { MdOutlineClose } from "react-icons/md";
import DataTable from "react-data-table-component";

const CustomerTable = () => {
  const [customerList, setCustomerList] = useState([]);
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const filteredItems = customerList.filter(
    (item) =>
      item.Name && item.Name.toLowerCase().includes(filterText.toLowerCase())
  );

  const { token } = useSelector((state) => state.user);

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <section className="flex justify-center w-full">
        <div className="border rounded overflow-hidden flex w-[90%] lg:w-1/2">
          <input
            type="text"
            className="px-4 py-2 w-full"
            placeholder="Search..."
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
          />
          <button
            className="flex items-center justify-center px-4 border-l"
            onClick={handleClear}
          >
            <MdOutlineClose />
          </button>
        </div>
      </section>
    );
  }, [filterText, resetPaginationToggle]);

  const columns = [
    {
      name: "Customer ID",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Customer Name",
      selector: (row) => row.Name,
      sortable: true,
      grow: 2,
    },
    {
      name: "Customer Contact",
      selector: (row) => row.Contact,
      sortable: true,
      grow: 2,
    },
    {
      name: "Branch",
      cell: (row) => {
        return (
          <div className="flex justify-end items-center gap-4">
            <Link
              to={`/customer/${row.id}`}
              className="font-semibold uppercase text-blue-700 py-1"
            >
              Manage Branch
            </Link>
          </div>
        );
      },
    },
  ];

  /* Theme Setting */
  const darkTheme = {
    title: {
      fontSize: "22px",
      fontColor: "#FFFFFF",
      backgroundColor: "#363640",
    },
    contextMenu: {
      backgroundColor: "#E91E63",
      fontColor: "#FFFFFF",
    },
    header: {
      fontSize: "12px",
      fontColor: "#FFFFFF",
      backgroundColor: "#363640",
    },
    rows: {
      fontColor: "#FFFFFF",
      backgroundColor: "#363640",
      borderColor: "rgba(255, 255, 255, .12)",
      hoverFontColor: "black",
      hoverBackgroundColor: "rgba(0, 0, 0, .24)",
    },
    cells: {
      cellPadding: "10px",
    },
  };

  useEffect(() => {
    api({
      method: "GET",
      url: `/api/all_customer`,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => {
        // console.log("api/all_customer", res.data);
        setCustomerList(res.data);
      })
      .catch((err) => {
        console.log(err.response.data);
        setCustomerList([]);
        if (err.response?.data?.detail === "Invalid token.") {
          return;
        }
        toast.error("Failed To Get All Customers");
      });
  }, [token]);
  return (
    <section className="w-full flex justify-center mt-4">
      <div className="w-full lg:w-3/4 overflow-x-autoblock border border-gray-300">
        <h3 className="text-center text-xl font-semibold mt-6 mb-4">
          Customer List
        </h3>
        <DataTable
          columns={columns}
          data={filteredItems}
          pagination
          paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          persistTableHead
          customTheme={darkTheme}
        />
      </div>
    </section>
  );
};

export default CustomerTable;
