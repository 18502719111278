import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import api from "../../api/api";

const MarkOutCard = ({ pack, setRefreshComponent, refreshComponent }) => {
  // const [packageData, setPackageData] = useState(pack);
  const [ordersDelivered, setOrdersDelivered] = useState("");
  const [reason, setReason] = useState("");
  const [reasonOther, setReasonOther] = useState("");
  const [appCleared, setAppCleared] = useState("");
  const [rtPackages, setRtPackages] = useState("");
  const [notes, setNotes] = useState("");
  const [loading, setLoading] = useState(false);

  const { token } = useSelector((state) => state.user);

  const handleMarkOut = (e) => {
    e.preventDefault();
    if (ordersDelivered.trim() === "") {
      toast.error("Enter total packages delivered");
      return;
    }
    if (pack?.PackageTaken < ordersDelivered) {
      toast.error("Delivered packages cannot be more than Packages Taken");
      return;
    }
    if (appCleared === "") {
      toast.error("Fill all fields");
      return;
    }

    const pendingOrders = pack?.PackageTaken - ordersDelivered;

    if (pendingOrders !== 0 && notes.trim() === "") {
      toast.error("Enter reason for pending orders");
      return;
    }

    const data = {
      PackageDelivered: Number(ordersDelivered),
      PackagePending: pendingOrders,
      PendingReason: JSON.stringify({
        reason,
        reasonOther,
        appCleared,
        notes,
        rtPackages,
      }),
    };
    console.log(data);

    setLoading(true);

    api({
      method: "PUT",
      url: `/api/end_delivery/${pack.id}`,
      headers: {
        Authorization: `Token ${token}`,
      },
      data: data,
    })
      .then((res) => {
        console.log(res.data);
        setRefreshComponent(!refreshComponent);
      })
      .catch((err) => {
        console.log(err.response.data);
        toast.error("Failed To Submit Form");
      })
      .finally(() => setLoading(false));
  };

  // useEffect(() => {
  //   api({
  //     method: "GET",
  //     url: `/api/get_package/${packageId}`,
  //     headers: {
  //       Authorization: `Token ${token}`,
  //     },
  //   })
  //     .then((res) => {
  //       console.log(res.data);
  //       setPackageData(res.data);
  //     })
  //     .catch((err) => {
  //       console.log(err.response.data);
  //     });
  // }, [packageId, token]);

  return (
    <form
      className="flex flex-col p-7 mt-5 w-full md:w-[60%] lg:w-[30%] shadow rounded border-2 border-gray-200"
      onSubmit={handleMarkOut}
    >
      <div>
        <h3 className="text-center font-semibold">
          {pack?.DeliveryDate} Report
        </h3>
      </div>
      <div className="divider m-0"></div>
      <div>
        <p className="font-semibold mb-1">
          Total Packages Taken: {pack?.PackageTaken}
        </p>
      </div>
      <div>
        <p className="font-semibold mb-1">Customer: {pack?.Customer}</p>
      </div>
      <div>
        <p className="font-semibold mb-1">Branch: {pack?.Branch}</p>
      </div>
      <label className="font-semibold mb-1 mt-1" htmlFor="ordersField">
        Packages Delivered
      </label>
      <input
        type="number"
        id="ordersField"
        name="ordersField"
        placeholder="Type here"
        className="input w-full bg-gray-100"
        value={ordersDelivered}
        onChange={(e) => {
          setOrdersDelivered(e.target.value);
          const pendingOrders = pack?.PackageTaken - e.target.value;
          if (pendingOrders === 0) {
            setReason("");
            setReasonOther("");
            setNotes("");
            setRtPackages("");
          }
        }}
        required
      />

      {ordersDelivered && ordersDelivered < pack?.PackageTaken && (
        <>
          <div className="divider my-4"></div>
          <label className="font-semibold mt-1 mb-1" htmlFor="reasonField">
            Reason for Return:
          </label>
          <select
            className="select w-full bg-gray-100"
            id="reasonField"
            name="reasonField"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            required
          >
            <option disabled value="">
              Select Reason for Return
            </option>
            <option value="Customer Not Present">Customer Not Present</option>
            <option value="Customer Will Not Accept">
              Customer Will Not Accept
            </option>
            <option value="Damaged">Damaged</option>
            <option value="Other">Other</option>
          </select>

          {reason === "Other" && (
            <>
              <label className="font-semibold mt-1 mb-1" htmlFor="reason4Field">
                Other
              </label>
              <input
                type="text"
                id="reason4Field"
                name="reason4Field"
                placeholder="Type here"
                className="input w-full bg-gray-100"
                value={reasonOther}
                onChange={(e) => setReasonOther(e.target.value)}
                required
              />
            </>
          )}

          <label className="font-semibold mt-1 mb-1" htmlFor="ordersField">
            What have you done with any of the returned packages?
          </label>
          <input
            type="text"
            id="ordersField"
            name="ordersField"
            placeholder="Type here"
            className="input w-full bg-gray-100"
            value={rtPackages}
            onChange={(e) => setRtPackages(e.target.value)}
            required
          />
          <label className="font-semibold mt-1 mb-1" htmlFor="ordersField">
            Notes
          </label>
          <input
            type="text"
            id="ordersField"
            name="ordersField"
            placeholder="Type here"
            className="input w-full bg-gray-100"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            required
          />
        </>
      )}

      <label className="font-semibold mt-1 mb-1" htmlFor="ordersField">
        Customer App Cleared at the end of the day
      </label>
      <div className="flex gap-2">
        <label className="label cursor-pointer flex gap-2">
          <input
            type="radio"
            name={`radio-m-1`}
            className="radio checked:bg-blue-500"
            checked={appCleared === "Yes"}
            onChange={(e) => {
              setAppCleared("Yes");
            }}
          />
          <span className="label-text">Yes</span>
        </label>
        <label className="label cursor-pointer flex gap-2">
          <input
            type="radio"
            name={`radio-m-1`}
            className="radio checked:bg-blue-500"
            checked={appCleared === "No"}
            onChange={(e) => {
              setAppCleared("No");
            }}
          />
          <span className="label-text">No</span>
        </label>
      </div>
      <div className="divider m-0 my-2"></div>

      {!loading ? (
        <button
          type="submit"
          className="flex items-center justify-center h-12 px-6 w-full bg-blue-600 mt-2 rounded font-semibold text-sm text-blue-100 hover:bg-blue-700"
        >
          Submit
        </button>
      ) : (
        <button
          disabled={loading}
          className="btn flex items-center justify-center h-12 px-6 w-full bg-blue-600 mt-8 rounded font-semibold text-sm text-blue-100 loading"
        >
          Loading...
        </button>
      )}
    </form>
  );
};

export default MarkOutCard;
