import React from "react";

const Footer = () => {
  return (
    <footer className="footer footer-center py-8 px-4 text-base-content font-semibold cursor-default">
      <div>
        <p>
          Copyright © 2022 - Developed By{" "}
          <a
            className="cursor-pointer text-blue-700"
            href="https://engagenreap.com/"
            target="_blank"
            rel="noreferrer"
          >
            EnR Team
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
