import React, { useEffect, useState } from "react";
import ReportTable from "./ReportTable";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import api from "../../../api/api";
import moment from "moment";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const Reports = () => {
  const [reportDate, setReportDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [reportsData, setReportsData] = useState([]);

  const { token } = useSelector((state) => state.user);
  // const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    getData();
  };

  const getData = () => {
    console.log(
      "Date Update: ",
      moment(reportDate).toISOString(true).split("T")[0]
    );
    setLoading(true);

    api({
      method: "GET",
      url: `/api/get_package_report/${
        moment(reportDate).toISOString(true).split("T")[0]
      }`,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => {
        console.log("/api/get_package_report ", res.data);
        const newArr =
          res.data.map((dat) => ({
            ...dat,
            ...JSON.parse(dat?.PendingReason),
            PackageConfirmation: JSON.parse(dat?.PackageConfirmation),
          })) || [];
        setReportsData(newArr);
      })
      .catch((err) => {
        console.log(err.response.data);
        if (err.response?.data?.detail === "Invalid token.") {
          return;
        }
        toast.error("Failed To Get Reports");
        setReportsData([]);

        // For Fixing Dynamic Errors generated by django sql
        //     const errStr = [];
        //     for (const data of Object.entries(errorObj)) {
        //       if (Array.isArray(data[1])) {
        //         errStr.push(`${data[0]}: ${data[1][0]}`);
        //       } else {
        //         errStr.push(data[1]);
        //       }
        //     }
        //     console.log(errStr.join("\n"));
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <>
      <section className="flex flex-col items-center justify-center w-screen min-h-[77vh] text-gray-700 border-2 border-slate-200 py-2">
        <form
          className="flex flex-col p-4 mt-5 w-full md:w-[60%] lg:w-[30%] shadow rounded border-2 border-gray-200"
          onSubmit={handleSubmit}
        >
          <label
            className="font-semibold text-xs mt-3 mb-1"
            htmlFor="dateField"
          >
            Date
          </label>
          <DatePicker
            className="input w-full bg-gray-100"
            selected={reportDate}
            onChange={(date) => setReportDate(date)}
            maxDate={new Date()}
          />
          {!loading ? (
            <button
              type="submit"
              disabled={loading}
              className="flex items-center justify-center h-12 px-6 w-full bg-blue-600 mt-8 rounded font-semibold text-sm text-blue-100 hover:bg-blue-700"
            >
              Generate Reports
            </button>
          ) : (
            <button
              disabled={loading}
              className="btn flex items-center justify-center h-12 px-6 w-full bg-blue-600 mt-8 rounded font-semibold text-sm text-blue-100 loading"
            >
              Loading...
            </button>
          )}
        </form>
        <section className="flex p-1 lg:p-5 gap-4 flex-wrap justify-center items-start w-screen min-h-[77.5vh] text-gray-700 mb-4">
          {reportsData && reportsData.length !== 0 ? (
            <ReportTable packageData={reportsData} />
          ) : (
            <h3 className="text-center my-2">No Reports Found</h3>
          )}
        </section>
      </section>
    </>
  );
};

export default Reports;
