import React, { useEffect, useLayoutEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import api from "../../../api/api";
// import ReportCard from "../../Reports/ReportCard";
// import PendingReport from "./PendingReport";
import ReportTable from "./ReportTable";

const UserReports = () => {
  const { token } = useSelector((state) => state.user);
  const { driverId } = useParams();

  const [reportData, setReportData] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false);

  const getData = (userId, token, activeTab) => {
    setLoading(true);
    api({
      method: "GET",
      url:
        activeTab === 0
          ? `/api/driver_delivered_package/${userId}`
          : `/api/driver_delivered_package_pending/${userId}`,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => {
        const newArr =
          res.data.map((dat) => ({
            ...dat,
            ...JSON.parse(dat?.PendingReason),
            PackageConfirmation: JSON.parse(dat?.PackageConfirmation),
          })) || [];
        // console.log("Driver_delivered_package", newArr);
        setReportData(newArr);
      })
      .catch((err) => {
        console.log(err.response.data);
        setReportData([]);
        if (err.response?.data?.detail === "Invalid token.") {
          return;
        }
        toast.error("Failed To Get Data");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getData(driverId, token, activeTab);
  }, [driverId, token, activeTab]);

  useLayoutEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  if (loading) {
    return (
      <section>
        <section className="flex p-1 lg:p-5 gap-x-4 gap-y-1  flex-wrap justify-center items-start w-screen min-h-[77.5vh] text-gray-700 mb-4">
          <h3 className="text-center font-semibold">Loading...</h3>
        </section>
      </section>
    );
  }

  return (
    <section>
      <div className="flex justify-center space-x-6 border-b mt-4">
        <button
          className={`py-2  border-b-4 transition-colors duration-300 ${
            activeTab === 0
              ? "border-teal-500"
              : "border-transparent hover:border-gray-200"
          } `}
          onClick={() => setActiveTab(0)}
        >
          Completed
        </button>
        <div className="divider divider-horizontal mx-2 lg:mx-4 before:bg-gray-200 after:bg-gray-200 py-2"></div>
        <button
          className={`py-2 border-b-4 transition-colors duration-300 ${
            activeTab === 1
              ? "border-teal-500"
              : "border-transparent hover:border-gray-200"
          }`}
          onClick={() => setActiveTab(1)}
        >
          Pending
        </button>
      </div>
      <section className="flex p-1 lg:p-5 gap-4 flex-wrap justify-center items-start w-screen min-h-[77.5vh] text-gray-700 mb-4">
        {reportData && reportData.length !== 0 ? (
          <ReportTable packageData={reportData} />
        ) : (
          <h3 className="text-center my-2">No Reports Found</h3>
        )}
      </section>
    </section>
  );
};

export default UserReports;
