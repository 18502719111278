import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { toast } from "react-hot-toast";
import { MdOutlineClose } from "react-icons/md";
import { useSelector } from "react-redux";
// import Select from "react-select";
import api from "../../../api/api";
import TableCell from "./TableCell";

const AssignTable = () => {
  const [assignList, setAssignList] = useState([]);
  const [pending, setPending] = React.useState(true);
  // const [filter, setFilter] = useState();
  const { token } = useSelector((state) => state.user);

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const re = new RegExp(filterText, "i");
  const filteredItems = assignList.filter((entry) =>
    Object.values(entry).some((val) => typeof val === "string" && val.match(re))
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <section className="flex flex-col lg:flex-row gap-2 justify-center w-full">
        <div className="border rounded overflow-hidden flex w-[90%] lg:w-1/2">
          <input
            type="text"
            className="px-4 py-1 w-full border rounded"
            placeholder="Search..."
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
          />
          <button
            className="flex items-center justify-center px-4 border-l"
            onClick={handleClear}
          >
            <MdOutlineClose />
          </button>
        </div>
        {/* <div className="w-[90%] lg:w-1/5">
          <Select
            className="basic-single w-full"
            classNamePrefix="select"
            isSearchable
            name="driver"
            value={filter}
            options={[
              { value: "All", label: "All" },
              { value: "Pending", label: "Pending" },
              { value: "Accepted", label: "Accepted" },
            ]}
            onChange={(selectedOption) => {
              setFilter(selectedOption);
            }}
            required
          />
        </div> */}
      </section>
    );
  }, [filterText, resetPaginationToggle]);

  const columns = [
    {
      name: "Date",
      selector: (row) => row.Date,
      sortable: true,
      grow: 2,
    },
    {
      name: "Driver Name",
      selector: (row) => row.DriverName,
      sortable: true,
      grow: 2,
    },
    {
      name: "Customer Name",
      selector: (row) => row.CustomerName,
      sortable: true,
      grow: 2,
    },
    {
      name: "Branch Name",
      selector: (row) => row.BranchName,
      sortable: true,
      grow: 2,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      cell: (row) => {
        return <div>{row.status}</div>;
      },
      sortable: true,
      grow: 2,
    },
    {
      name: "Actions",
      cell: (row) => {
        return <TableCell row={row} />;
      },
      grow: 2,
    },
  ];

  /* Theme Setting */
  const darkTheme = {
    title: {
      fontSize: "22px",
      fontColor: "#FFFFFF",
      backgroundColor: "#363640",
    },
    contextMenu: {
      backgroundColor: "#E91E63",
      fontColor: "#FFFFFF",
    },
    header: {
      fontSize: "12px",
      fontColor: "#FFFFFF",
      backgroundColor: "#363640",
    },
    rows: {
      fontColor: "#FFFFFF",
      backgroundColor: "#363640",
      borderColor: "rgba(255, 255, 255, .12)",
      hoverFontColor: "black",
      hoverBackgroundColor: "rgba(0, 0, 0, .24)",
    },
    cells: {
      cellPadding: "10px",
    },
  };

  useEffect(() => {
    setPending(true);
    api({
      method: "GET",
      url: `/api/all_package_assigned`,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => {
        // console.log("/api/all_package_assigned", res.data);

        const newArr =
          res.data.map((dat) => ({
            ...dat,
            status: dat.IsAccept ? "Accepted" : "Pending",
          })) || [];

        setAssignList(newArr);
        setPending(false);
      })
      .catch((err) => {
        console.log(err.response.data);
        setAssignList([]);
        setPending(false);
        if (err.response?.data?.detail === "Invalid token.") {
          return;
        }
        toast.error("Failed To Get Data");
      });
  }, [token]);

  return (
    <section className="w-full flex justify-center mt-4">
      <div className="w-full lg:w-3/4 overflow-x-autoblock border border-gray-300">
        <h3 className="text-center text-xl font-semibold mt-6 mb-4">
          Assign List
        </h3>
        <DataTable
          columns={columns}
          data={filteredItems}
          pagination
          paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          persistTableHead
          customTheme={darkTheme}
          progressPending={pending}
          // customStyles={tableCustomStyles}
        />
      </div>
    </section>
  );
};

export default AssignTable;
