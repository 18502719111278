import React, { useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Profile = () => {
  const { user, role } = useSelector((state) => state.user);

  useLayoutEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="container mx-auto max-w-2xl my-8 ">
      <h1 className="text-2xl font-bold text-gray-700 px-6 md:px-0">
        My Profile
      </h1>

      <div>
        <div className="w-full bg-white rounded-lg mx-auto lg:mt-8 flex overflow-hidden rounded-b-none lg:shadow-lg lg:border-2 lg:border-gray-200">
          <div className="w-1/3 bg-gray-100 p-8 hidden md:inline-block">
            <h2 className="font-medium text-md text-gray-700 mb-4 tracking-wide">
              Profile Info
            </h2>
            <p className="text-xs text-gray-500">
              Your basic profile information such as Email Address, Name, and
              Image.
            </p>
          </div>
          <div className="md:w-2/3 w-full">
            <div className="py-8 flex justify-center">
              <img
                className="rounded-full w-32 h-32 border-4 mt-2 border-gray-200"
                id="photo"
                src={
                  user?.ProfilePic
                    ? user?.ProfilePic
                    : "https://images.unsplash.com/photo-1582395787198-630d7fb2bfbf?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=595&q=80"
                }
                alt="dddd"
              />
              {/*<div className="bg-gray-200 text-gray-500 text-xs mt-5 ml-3 font-bold px-4 py-2 rounded-lg float-left hover:bg-gray-300 hover:text-gray-600 relative overflow-hidden cursor-pointer">
                <input
                  type="file"
                  name="photo"
                  onchange="loadFile(event)"
                  className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                />{" "}
                Change Photo
              </div>{" "}
              */}
            </div>
            <hr className="border-gray-200 " />
            <div className="py-4 px-10">
              <div
                htmlFor="name"
                className="text-sm text-gray-600 font-semibold"
              >
                Name
              </div>
              <div htmlFor="name" className="text-sm text-gray-600 ">
                {`${user?.first_name} ${user?.last_name}`}
              </div>
            </div>
            <div className="pb-4 px-10">
              <div
                htmlFor="name"
                className="text-sm text-gray-600 font-semibold"
              >
                Email
              </div>
              <div htmlFor="name" className="text-sm text-gray-600 ">
                {user?.email}
              </div>
            </div>
            <div className="pb-4 px-10">
              <div
                htmlFor="name"
                className="text-sm text-gray-600 font-semibold"
              >
                Phone
              </div>
              <div htmlFor="name" className="text-sm text-gray-600 ">
                {user?.Phone}
              </div>
            </div>
            {role === "Admin" ? (
              <div className="pb-4 px-10">
                <div
                  htmlFor="name"
                  className="text-sm text-gray-600 font-semibold"
                >
                  Role
                </div>
                <div htmlFor="name" className="text-sm text-gray-600 ">
                  Admin
                </div>
              </div>
            ) : (
              <>
                <div className="pb-4 px-10">
                  <div
                    htmlFor="name"
                    className="text-sm text-gray-600 font-semibold"
                  >
                    Vehicle
                  </div>
                  <div htmlFor="name" className="text-sm text-gray-600 ">
                    {user?.vehicle_detail[0]?.Vehicle}
                  </div>
                </div>
                <div className="py-4 px-10">
                  <div
                    htmlFor="name"
                    className="text-sm text-gray-600 font-semibold"
                  >
                    Vehicle Number
                  </div>
                  <div htmlFor="name" className="text-sm text-gray-600 ">
                    {user?.vehicle_detail[0]?.VehicleNumber}
                  </div>
                </div>
              </>
            )}
            {/* <hr className="border-gray-200 " />
            {user?.vehicle_detail[0]?.Confirmation &&
              JSON.parse(user?.vehicle_detail[0]?.Confirmation).map((q) => (
                <div className="py-4 px-10">
                  <div for="name" className="text-sm text-gray-600 font-semibold">
                    {q.que}
                  </div>
                  <div for="name" className="text-sm text-gray-600 ">
                    {q.ans}
                  </div>
                </div>
              ))} */}

            <hr className="border-gray-200 " />

            <div className="flex justify-center gap-4 py-4">
              <Link
                to="/change-password"
                className="flex items-center justify-center h-12 px-6 bg-blue-600 rounded font-semibold text-sm text-blue-100 hover:bg-blue-700"
              >
                Change Password
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
