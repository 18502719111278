import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import api from "../../../api/api";
import { MdOutlineClose } from "react-icons/md";

const BranchTable = () => {
  const [branchList, setBranchList] = useState([]);
  const { customerId } = useParams();

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const filteredItems = branchList.filter(
    (item) =>
      item.BranchName &&
      item.BranchName.toLowerCase().includes(filterText.toLowerCase())
  );

  const { token } = useSelector((state) => state.user);

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <section className="flex justify-center w-full">
        <div className="border rounded overflow-hidden flex w-[90%] lg:w-1/2">
          <input
            type="text"
            className="px-4 py-2 w-full"
            placeholder="Search..."
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
          />
          <button
            className="flex items-center justify-center px-4 border-l"
            onClick={handleClear}
          >
            <MdOutlineClose />
          </button>
        </div>
      </section>
    );
  }, [filterText, resetPaginationToggle]);

  const columns = [
    {
      name: "Branch ID",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Branch Name",
      selector: (row) => row.BranchName,
      sortable: true,
      grow: 2,
    },
    {
      name: "Branch Contact",
      selector: (row) => row.BranchContact,
      sortable: true,
      grow: 2,
    },
    {
      name: "Address",
      selector: (row) => row.Address,
      sortable: true,
      grow: 2,
    },
  ];

  /* Theme Setting */
  const darkTheme = {
    title: {
      fontSize: "22px",
      fontColor: "#FFFFFF",
      backgroundColor: "#363640",
    },
    contextMenu: {
      backgroundColor: "#E91E63",
      fontColor: "#FFFFFF",
    },
    header: {
      fontSize: "12px",
      fontColor: "#FFFFFF",
      backgroundColor: "#363640",
    },
    rows: {
      fontColor: "#FFFFFF",
      backgroundColor: "#363640",
      borderColor: "rgba(255, 255, 255, .12)",
      hoverFontColor: "black",
      hoverBackgroundColor: "rgba(0, 0, 0, .24)",
    },
    cells: {
      cellPadding: "10px",
    },
  };

  // const tableCustomStyles = {
  //   headCells: {
  //     style: {
  //       fontSize: "16px",
  //       fontWeight: "600",
  //       border: "1px solid rgb(209 213 219 / 1)",
  //     },
  //   },
  // };

  useEffect(() => {
    api({
      method: "GET",
      url: `/api/get_customer/${customerId}`,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => {
        // console.log("/api/get_customer/", res.data);
        setBranchList(res?.data?.branch || []);
      })
      .catch((err) => {
        console.log(err.response.data);
        setBranchList([]);
        if (err.response?.data?.detail === "Invalid token.") {
          return;
        }
        toast.error("Failed To Get All Branches");
      });
  }, [token, customerId]);

  return (
    <section className="w-full flex justify-center mt-4">
      <div className="w-full lg:w-3/4 overflow-x-autoblock border border-gray-300">
        <h3 className="text-center text-xl font-semibold mt-6 mb-4">
          Branch List
        </h3>
        <DataTable
          columns={columns}
          data={filteredItems}
          pagination
          paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          persistTableHead
          customTheme={darkTheme}
          // customStyles={tableCustomStyles}
        />
      </div>
    </section>
  );
};

export default BranchTable;
