import React from "react";
import Banner from "../../components/Banner/Banner";
import FooterNew from "../../components/Footer/FooterNew";
import FeaturesSection from "./FeaturesSection";
import FrameSection from "./FrameSection";
import InfoSection from "./InfoSection";

const LandingPage = () => {
  return (
    <div className="min-h-[88vh] box-border">
      <Banner />
      <FeaturesSection />
      <InfoSection />
      <FrameSection />

      <FooterNew />
    </div>
  );
};

export default LandingPage;
