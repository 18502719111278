import React, { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import Select from "react-select";
import api from "../../../api/api";
import DatePicker from "react-datepicker";
import moment from "moment";

import "react-datepicker/dist/react-datepicker.css";

const AssignForm = ({ loading, setLoading }) => {
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [date, setDate] = useState(new Date());
  const { token } = useSelector((state) => state.user);
  const [driverList, setDriverList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [branchList, setBranchList] = useState([]);

  const handleAssign = (e) => {
    e.preventDefault();
    if (!selectedCustomer || !selectedBranch || !selectedBranch) {
      toast.error("Select All Fields");
      return;
    }

    const formattedDate = moment(date).toISOString(true).split("T")[0];

    const data = {
      CustomerId: selectedCustomer?.value,
      BranchId: selectedBranch?.value,
      DriverId: selectedDriver?.value,
      Date: formattedDate,
    };
    // console.log(data);

    setLoading(true);
    api({
      method: "POST",
      url: "/api/add_package_assign",
      headers: {
        Authorization: `Token ${token}`,
      },
      data: data,
    })
      .then((res) => {
        // console.log("/api/add_package_assign", res.data);
        toast.success("Package assigned successfully");
        window.location.reload();
      })
      .catch((err) => {
        console.log(err.response.data);
        toast.error(err.response?.data?.detail || "Failed To Assign Driver");
      })
      .finally(() => setLoading(false));
  };

  const handleDriverChange = (selectedOption) => {
    // console.log(`Option selected:`, selectedOption);
    setSelectedDriver(selectedOption);
  };
  const handleCustomerChange = (selectedOption) => {
    // console.log(`Option selected:`, selectedOption);
    setBranchList(selectedOption.branchList || []);
    setSelectedCustomer(selectedOption);
  };
  const handleBranchChange = (selectedOption) => {
    // console.log(`Option selected:`, selectedOption);
    setSelectedBranch(selectedOption);
  };

  useEffect(() => {
    api({
      method: "GET",
      url: "/api/driver_active",
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => {
        // console.log("driver_active", res.data);
        const newArr = res?.data?.map((dat) => {
          return {
            value: dat.id,
            label: `${dat.first_name} ${dat.last_name}`,
          };
        });
        setDriverList(newArr);
      })
      .catch((err) => {
        console.log(err.response.data);
        setDriverList([]);
        toast.error("Failed To Load Data");
      });
  }, [token]);

  useEffect(() => {
    api({
      method: "GET",
      url: "/api/all_customer",
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => {
        const newArr =
          res?.data?.map((dat) => {
            return {
              value: dat.id,
              label: `${dat.Name}`,
              branchList: dat.branch.map((b) => {
                return { value: b.id, label: b.BranchName };
              }),
            };
          }) || [];
        // console.log("all_customer", newArr);
        setCustomerList(newArr);
      })
      .catch((err) => {
        console.log(err.response.data);
        setCustomerList([]);
        // toast.error("Failed To Get Customer List");
      });
  }, [token]);

  return (
    <form
      // className="flex flex-col bg-white rounded shadow-lg p-7 mt-2 w-full md:w-[60%] lg:w-[30%]"
      onSubmit={handleAssign}
    >
      <h1 className="font-bold text-2xl mb-4 text-center">Assign Driver</h1>
      <label className="font-semibold text-xs mb-1" htmlFor="branchNameField">
        Select Driver
      </label>
      <Select
        className="basic-single"
        classNamePrefix="select"
        isSearchable
        name="driver"
        options={driverList}
        onChange={handleDriverChange}
        required
      />
      <label
        className="font-semibold text-xs mt-3 mb-1"
        htmlFor="branchPhoneField"
      >
        Select Customer
      </label>
      <Select
        className="basic-single"
        classNamePrefix="select"
        isSearchable
        name="customer"
        isDisabled={!selectedDriver ? true : false}
        options={customerList}
        onChange={handleCustomerChange}
        required
      />
      <label
        className="font-semibold text-xs mt-3 mb-1"
        htmlFor="branchAddressField"
      >
        Select Branch
      </label>
      <Select
        className="basic-single"
        classNamePrefix="select"
        isSearchable
        name="customer"
        isDisabled={!selectedCustomer ? true : false}
        options={branchList}
        onChange={handleBranchChange}
        required
      />
      <label
        className="font-semibold text-xs mt-3 mb-1"
        htmlFor="branchAddressField"
      >
        Date
      </label>
      <DatePicker
        className="input w-full bg-gray-100"
        selected={date}
        onChange={(date) => setDate(date)}
        minDate={new Date()}
        popperPlacement="top"
      />

      {!loading ? (
        <button
          type="submit"
          disabled={loading}
          className="flex items-center justify-center h-12 px-6 w-full bg-blue-600 mt-8 rounded font-semibold text-sm text-blue-100 hover:bg-blue-700"
        >
          Assign
        </button>
      ) : (
        <button
          disabled={loading}
          className="btn flex items-center justify-center h-12 px-6 w-full bg-blue-600 mt-8 rounded font-semibold text-sm text-blue-100 loading"
        >
          Loading...
        </button>
      )}
    </form>
  );
};

export default AssignForm;
