import { Route, Routes } from "react-router-dom";
import Assigned from "../../pages/Assigned/Assigned";
import Home from "../../pages/Home/Home";
import Profile from "../../pages/Profile/Profile";
import Reports from "../../pages/Reports/Reports";
import UpdatePass from "../../pages/UpdatePass/UpdatePass";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";

function PrivateRoutes(props) {
  return (
    <div>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/delivered" element={<Reports />} />
        <Route exact path="/assigned" element={<Assigned />} />
        <Route exact path="/profile" element={<Profile />} />
        <Route exact path="/change-password" element={<UpdatePass />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default PrivateRoutes;
