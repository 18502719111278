import React, { useState } from "react";
import { IoCloseCircle } from "react-icons/io5";

const QueModal = ({ ques, id }) => {
  const [openModal, setOpenModal] = useState(false);
  return (
    <>
      <div className="flex justify-center items-center gap-4">
        <div
          onClick={(e) => setOpenModal(!openModal)}
          className="font-semibold uppercase text-blue-700 py-1 cursor-pointer"
        >
          View
        </div>
      </div>

      {/* Modal  */}
      <input
        type="checkbox"
        id="my-modal"
        className="modal-toggle"
        checked={openModal}
        readOnly
      />
      <div
        key={`modal-${id}`}
        className="modal"
        onClick={(e) => setOpenModal(!openModal)}
      >
        <div className="modal-box relative overflow-scroll rounded-none p-4">
          <div className="modal-action absolute -top-3 right-2 text-red-600 cursor-pointer">
            <IoCloseCircle
              htmlFor="my-modal"
              size={28}
              onClick={(e) => setOpenModal(!openModal)}
            />
            {/* <button
              onClick={(e) => setOpenModal(!openModal)}
              htmlFor="my-modal"
              className="p-4 rounded-full bg-blue-400"
            >
              X
            </button> */}
          </div>

          <div className="divider m-0 mt-6"></div>
          {ques &&
            ques.map((q, index) => (
              <div key={index}>
                {" "}
                <div className="mb-2 flex justify-between items-center">
                  <p className="font-semibold w-4/5">{q?.que}</p>
                  <p className="font-semibold w-1/5 text-center">{q?.ans}</p>
                </div>
                <div className="divider m-0"></div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default QueModal;
