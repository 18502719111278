import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import api from "../../api/api";
// import Banner from "../../components/Banner/Banner";
import { login } from "../../redux/user/userSlice";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleLogin = (e) => {
    e.preventDefault();
    console.log("User Login", { email, password });
    if (email.trim() === "" || password.trim() === "") {
      return toast.error("Enter email and password");
    }
    setLoading(true);

    api({
      method: "POST",
      url: "/api/login",
      data: { username: email, password },
    })
      .then((res) => {
        console.log(res.data);
        navigate("/");
        dispatch(login(res?.data));
        toast.success("Logged in Successfully");
      })
      .catch((err) => {
        console.log(err.response);
        if (Array.isArray(err.response.data.non_field_errors)) {
          if (
            err.response.data.non_field_errors[0] ===
            "Unable to log in with provided credentials."
          ) {
            toast.error("Email or Password is Incorrect");
            return;
          }
        }
        toast.error(err?.response?.data?.detail || "Failed To Login");
      })
      .finally(() => setLoading(false));
    // dispatch(login("testing_only"));
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 justify-center items-center w-screen min-h-[88vh]">
      {/* <div className="lg:hidden flex-1 flex justify-center items-center py-2">
        <Link
          to="/"
          className="font-semibold text-lg flex justify-center items-center gap-1"
        >
          <img className="w-12 h-12" src="/images/enr.png" alt="EnR brand" />
          <div className="divider divider-horizontal mx-2 lg:mx-4 before:bg-gray-300 after:bg-gray-300 py-6"></div>
          <img
            className="w-32 md:w-40"
            src="/images/Wheelz_crop.jpg"
            alt="EnR Wheelz"
          />
        </Link>
      </div> */}
      <section className="flex justify-center items-center">
        <img
          className="w-3/4"
          src="/images/delivery_img.png"
          alt="delivery_img"
        />
      </section>
      {/* <Banner /> */}
      <section className="flex flex-col items-center justify-center text-gray-700">
        {/* <div className="flex-1 hidden lg:flex justify-between items-center">
          <Link
            to="/"
            className="font-semibold text-lg flex justify-center items-center gap-1"
          >
            <img className="w-12 h-12" src="/images/enr.png" alt="EnR brand" />
            <div className="divider divider-horizontal mx-2 lg:mx-4 before:bg-gray-300 after:bg-gray-300 py-6"></div>
            <img
              className="w-32 md:w-40"
              src="/images/Wheelz_crop.jpg"
              alt="EnR Wheelz"
            />
          </Link>
        </div> */}
        <div className="text-center mt-4">
          <h2 className="mb-2 font-bold text-2xl text-center">
            Welcome to Branded WheelzPro!
          </h2>
          <p>To begin, please Log in with your details</p>
        </div>
        <form
          className="flex flex-col bg-white p-5 mt-2 w-full md:w-[80%]"
          onSubmit={handleLogin}
        >
          <h1 className="font-bold text-2xl mb-4 text-center">Login</h1>
          <label className="font-semibold text-xs mb-1" htmlFor="usernameField">
            Email
          </label>
          <input
            type="text"
            placeholder="Type here"
            className="input w-full bg-gray-100"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            autoComplete="on"
          />
          <label
            className="font-semibold text-xs mt-3 mb-1"
            htmlFor="passwordField"
          >
            Password
          </label>
          <input
            type="password"
            placeholder="Type here"
            className="input w-full bg-gray-100"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            autoComplete="on"
          />
          <div className="flex flex-col lg:flex-row items-center justify-center gap-2 mt-8">
            {!loading ? (
              <button
                type="submit"
                className="h-12 px-12 rounded-xl font-semibold text-sm text-white bg-slate-800 hover:bg-slate-800"
                disabled={loading}
              >
                Log In
              </button>
            ) : (
              <button className="btn loading">Logging in</button>
            )}
            <Link
              to="/signup"
              className="h-12 px-12 rounded-xl font-semibold text-sm text-[#5EA11D] border-2 border-[#5EA11D] flex justify-center items-center"
            >
              I want to register
            </Link>
          </div>
          <div className="flex mt-6 justify-center text-xs">
            <Link
              to="/reset-password"
              className="text-blue-400 hover:text-blue-500"
            >
              Forgot Password
            </Link>
          </div>
          {/* <div className="flex mt-6 justify-center text-xs gap-2">
            <span>Don't have account?</span>
            <Link to="/signup" className="text-blue-400 hover:text-blue-500">
              Sign Up
            </Link>
          </div> */}
        </form>
        <div className="mt-4">
          <p>
            Copyright © 2022 - Developed By{" "}
            <a
              className="cursor-pointer text-blue-700"
              href="https://engagenreap.com/"
              target="_blank"
              rel="noreferrer"
            >
              EnR Team
            </a>
          </p>
        </div>
      </section>
    </div>
  );
};

export default Login;
