import React from "react";
import DataTable from "react-data-table-component";
import { toast } from "react-hot-toast";
import { MdOutlineClose } from "react-icons/md";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import api from "../../../api/api";

const FilterTable = ({ UserList, title }) => {
  const [filterText, setFilterText] = React.useState("");

  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const filteredItems = UserList.filter(
    (item) =>
      item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
  );

  const { token } = useSelector((state) => state.user);

  const handleActiveDeactive = (id) => {
    setLoading(true);
    api({
      method: "PUT",
      url: `/api/driver_status/${id}`,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => {
        // console.log("/api/driver_status/", res.data);
        toast.success("Updated Successfully");
        window.location.reload();
      })
      .catch((err) => {
        console.log(err.response.data);
        toast.error("Failed To Update");
      })
      .finally(() => setLoading(false));
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <section className="flex justify-center w-full">
        <div className="border rounded overflow-hidden flex w-[90%] lg:w-1/2">
          <input
            type="text"
            className="px-4 py-2 w-full"
            placeholder="Search..."
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
          />
          <button
            className="flex items-center justify-center px-4 border-l"
            onClick={handleClear}
          >
            <MdOutlineClose />
          </button>
        </div>
      </section>
    );
  }, [filterText, resetPaginationToggle]);

  const columns = [
    {
      name: "Driver ID",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      grow: 2,
    },
    {
      name: "Phone",
      selector: (row) => row.Phone,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      grow: 2,
    },
    {
      name: "Reports",
      cell: (row) => {
        return (
          <div className="flex justify-end items-center gap-4">
            <Link
              to={`/user-report/${row.id}`}
              className="font-semibold uppercase text-blue-700 py-1"
            >
              View
            </Link>
          </div>
        );
      },
    },
    {
      name: "Status",
      cell: (row) => {
        return (
          <div className="flex justify-end items-center gap-4">
            <input
              type="checkbox"
              className="toggle toggle-primary"
              checked={row.status}
              onChange={(e) => handleActiveDeactive(row.id)}
              disabled={loading}
            />
          </div>
        );
      },
    },
  ];

  /* Theme Setting */
  // const darkTheme = {
  //   title: {
  //     fontSize: "22px",
  //     fontColor: "#FFFFFF",
  //     backgroundColor: "#363640",
  //   },
  //   contextMenu: {
  //     backgroundColor: "#E91E63",
  //     fontColor: "#FFFFFF",
  //   },
  //   header: {
  //     fontSize: "12px",
  //     fontColor: "#FFFFFF",
  //     backgroundColor: "#363640",
  //   },
  //   rows: {
  //     fontColor: "#FFFFFF",
  //     backgroundColor: "#363640",
  //     borderColor: "rgba(255, 255, 255, .12)",
  //     hoverFontColor: "black",
  //     hoverBackgroundColor: "rgba(0, 0, 0, .24)",
  //   },
  //   cells: {
  //     cellPadding: "10px",
  //   },
  // };
  return (
    <section className="flex justify-center mt-4">
      <div className="w-full lg:w-3/4 overflow-x-autoblock border border-gray-300">
        <h3 className="text-center text-xl font-semibold mt-6 mb-4">{title}</h3>
        <DataTable
          columns={columns}
          data={filteredItems}
          pagination
          paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          persistTableHead
          // customTheme={darkTheme}
        />
      </div>
    </section>
  );
};

export default FilterTable;
