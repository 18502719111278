import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { IoMdRemoveCircle, IoIosAddCircle } from "react-icons/io";
import { useSelector } from "react-redux";
import api from "../../../api/api";

const AddForm = () => {
  const [customerName, setCustomerName] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [formValues, setFormValues] = useState([
    { BranchName: "", BranchContact: "", Address: "" },
  ]);
  const [loading, setLoading] = useState(false);
  const { token } = useSelector((state) => state.user);

  let handleChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  };

  let addFormFields = () => {
    setFormValues([
      ...formValues,
      { BranchName: "", BranchContact: "", Address: "" },
    ]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  const handleAddCustomer = (e) => {
    e.preventDefault();

    if (customerName.trim() === "" || customerPhone === "") {
      toast.error("Please Fill All Fields");
      return;
    }

    setLoading(true);
    const data = [
      {
        Name: customerName,
        Contact: customerPhone,
        branch: formValues,
      },
    ];
    // console.log(data);

    api({
      method: "POST",
      url: `/api/customer_add`,
      headers: {
        Authorization: `Token ${token}`,
      },
      data: data,
    })
      .then((res) => {
        // console.log("/api/customer_add", res.data);
        toast.success("Customer Added Successfully");
        window.location.reload();
      })
      .catch((err) => {
        console.log(err.response.data);
        toast.error(err?.response?.data?.detail || "Failed To Add Customer");
      })
      .finally(() => setLoading(false));
  };

  return (
    <form
      // className="flex flex-col bg-white rounded shadow-lg p-7 mt-2 w-full md:w-[60%] lg:w-[30%]"
      onSubmit={handleAddCustomer}
    >
      <h2 className="font-bold text-2xl mb-4 text-center">Add New Customer</h2>
      <label className="font-semibold text-xs mb-1" htmlFor="customerNameField">
        Customer Name
      </label>
      <input
        id="customerNameField"
        name="customerNameField"
        type="text"
        placeholder="Type here"
        className="input w-full bg-gray-100"
        value={customerName}
        onChange={(e) => setCustomerName(e.target.value)}
        required
      />
      <label
        className="font-semibold text-xs mt-3 mb-1"
        htmlFor="customerPhoneField"
      >
        Phone
      </label>
      <input
        id="customerPhoneField"
        name="customerPhoneField"
        type="text"
        placeholder="Type here"
        className="input w-full bg-gray-100"
        value={customerPhone}
        onChange={(e) => setCustomerPhone(e.target.value)}
        required
      />
      <div className="divider my-2"></div>
      <div className="flex justify-between items-center">
        <h2 className="font-bold text-2xl mb-2 text-center">Add Branch</h2>
        <IoIosAddCircle
          className="cursor-pointer text-green-600"
          size={28}
          onClick={addFormFields}
        />
      </div>
      <div className="divider my-2"></div>
      {formValues &&
        formValues.map((element, index) => (
          <div key={index}>
            <h3 className="font-semibold text-lg mb-2 flex justify-between">
              <span> Branch {index + 1}:</span>{" "}
              <IoMdRemoveCircle
                className="cursor-pointer text-red-600"
                size={28}
                onClick={(e) => removeFormFields(index)}
              />
            </h3>
            <label className="font-semibold text-xs mb-1">Branch Name</label>
            <input
              name="BranchName"
              type="text"
              placeholder="Type here"
              className="input w-full bg-gray-100"
              value={element.BranchName || ""}
              onChange={(e) => handleChange(index, e)}
              required
            />
            <label className="font-semibold text-xs mb-1">Branch Phone</label>
            <input
              name="BranchContact"
              type="text"
              placeholder="Type here"
              className="input w-full bg-gray-100"
              value={element.BranchContact || ""}
              onChange={(e) => handleChange(index, e)}
              required
            />
            <label className="font-semibold text-xs mb-1">Branch Address</label>
            <input
              name="Address"
              type="text"
              placeholder="Type here"
              className="input w-full bg-gray-100"
              value={element.Address || ""}
              onChange={(e) => handleChange(index, e)}
              required
            />
            <div className="divider m-0"></div>
          </div>
        ))}
      {/* <span
        onClick={addFormFields}
        className="flex items-center justify-center h-12 w-full bg-blue-600 rounded font-semibold text-sm text-blue-100 hover:bg-blue-700"
      >
        Add More
      </span>
      <div className="divider m-0"></div> */}

      {!loading ? (
        <button
          type="submit"
          disabled={loading}
          className="flex items-center justify-center h-12 px-6 w-full bg-blue-600 mt-4 rounded font-semibold text-sm text-blue-100 hover:bg-blue-700"
        >
          Submit
        </button>
      ) : (
        <button
          disabled={loading}
          className="btn flex items-center justify-center h-12 px-6 w-full bg-blue-600 mt-8 rounded font-semibold text-sm text-blue-100 loading"
        >
          Loading...
        </button>
      )}
    </form>
  );
};

export default AddForm;
