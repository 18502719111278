import React from "react";

const Stats = ({ total, active }) => {
  return (
    <section className="flex justify-center mt-4">
      <div className="stats shadow-md">
        <div className="stat">
          <div className="stat-title font-semibold">Active Drivers</div>
          <div className="stat-value text-primary">{active ?? 0}</div>
        </div>

        <div className="stat">
          <div className="stat-title font-semibold">Total Drivers</div>
          <div className="stat-value text-primary">{total ?? 0}</div>
        </div>
      </div>
    </section>
  );
};

export default Stats;
