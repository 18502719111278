import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import api from "../../api/api";

const ResetConfirm = () => {
  const [token, setToken] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setCPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleReset = (e) => {
    e.preventDefault();

    if (token.trim() === "" || password.trim() === "") {
      return toast.error("Enter Token And New Password");
    }

    if (password !== cpassword) {
      return toast.error("Password And Confirm Password Not Matching");
    }

    setLoading(true);

    api({
      method: "POST",
      url: "/api/password_reset/confirm/",
      data: { token, password },
    })
      .then((res) => {
        console.log(res.data);
        toast.success("Password Changed Successfully");
        navigate("/");
      })
      .catch((err) => {
        console.log(err.response);
        if (Array.isArray(err?.response?.data?.password)) {
          return toast.error(err?.response?.data.password[0]);
        }
        toast.error(err?.response?.data?.detail || "Failed To Change Password");
      })
      .finally(() => setLoading(false));
  };
  return (
    <section className="flex flex-col items-center justify-center w-screen min-h-[88vh] bg-gray-200 text-gray-700">
      <h1 className="font-bold text-2xl">Reset Password</h1>
      <form
        className="flex flex-col bg-white rounded shadow-lg p-7 mt-12 w-full md:w-[60%] lg:w-[30%]"
        onSubmit={handleReset}
      >
        <label className="font-semibold text-xs mb-1" htmlFor="usernameField">
          Token
        </label>
        <input
          type="text"
          placeholder="Type here"
          className="input w-full bg-gray-100"
          value={token}
          onChange={(e) => setToken(e.target.value)}
        />

        <label className="font-semibold text-xs mb-1" htmlFor="usernameField">
          New Password
        </label>
        <input
          type="password"
          placeholder="Type here"
          className="input w-full bg-gray-100"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          autoComplete="new-password"
        />

        <label className="font-semibold text-xs mb-1" htmlFor="usernameField">
          Confirm New Password
        </label>
        <input
          type="password"
          placeholder="Type here"
          className="input w-full bg-gray-100"
          value={cpassword}
          onChange={(e) => setCPassword(e.target.value)}
          autoComplete="new-password"
        />

        {!loading ? (
          <button
            type="submit"
            className="flex items-center justify-center h-12 px-6 w-full bg-blue-600 mt-8 rounded font-semibold text-sm text-blue-100 hover:bg-blue-700"
          >
            Submit
          </button>
        ) : (
          <button
            disabled={loading}
            className="btn flex items-center justify-center h-12 px-6 w-full bg-blue-600 mt-8 rounded font-semibold text-sm text-blue-100 loading"
          >
            Loading...
          </button>
        )}
        <div className="flex mt-6 justify-center text-xs gap-2">
          <span>Go back to</span>
          <Link to="/login" className="text-blue-400 hover:text-blue-500">
            Login
          </Link>
        </div>
      </form>
    </section>
  );
};

export default ResetConfirm;
