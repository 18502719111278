import React from "react";
import { Link } from "react-router-dom";
import { FaFacebook, FaInstagramSquare, FaTwitterSquare } from "react-icons/fa";

const FooterNew = () => {
  return (
    <footer>
      <section className="footer p-10 bg-base-200 text-base-content font-semibold">
        <div className="w-full lg:w-full flex flex-col justify-center items-center gap-4">
          <img
            className="w-full md:w-48"
            src="/images/wheelz_logo2.png"
            alt="whz_logo"
          />
          <div className="w-full flex justify-center items-center gap-4">
            <a href="https://facebook.com" target="_blank" rel="noreferrer">
              <FaFacebook size={28} />
            </a>
            <a href="https://instagram.com" target="_blank" rel="noreferrer">
              <FaInstagramSquare size={28} />
            </a>
            <a href="https://twitter.com" target="_blank" rel="noreferrer">
              <FaTwitterSquare size={28} />
            </a>
          </div>
        </div>

        <div className="w-full">
          <span className="footer-title">Services</span>
          <Link className="link link-hover">Branding</Link>
          <Link className="link link-hover">Design</Link>
          <Link className="link link-hover">Marketing</Link>
          <Link className="link link-hover">Advertisement</Link>
        </div>
        <div className="w-full">
          <span className="footer-title">Company</span>
          <Link className="link link-hover">About us</Link>
          <Link className="link link-hover">Contact</Link>
          <Link className="link link-hover">Jobs</Link>
          <Link className="link link-hover">Press kit</Link>
        </div>
        <div className="w-full">
          <span className="footer-title">Legal</span>
          <Link className="link link-hover">Terms of use</Link>
          <Link className="link link-hover">Privacy policy</Link>
          <Link className="link link-hover">Cookie policy</Link>
        </div>
      </section>

      <section className="footer footer-center bg-base-200 py-6 px-4 text-base-content font-semibold cursor-default border-t-2 border-gray-200">
        <div>
          <p>
            Copyright © 2022 - Developed By{" "}
            <a
              className="cursor-pointer text-blue-700"
              href="https://engagenreap.com/"
              target="_blank"
              rel="noreferrer"
            >
              EnR Team
            </a>
          </p>
        </div>
      </section>
    </footer>
  );
};

export default FooterNew;
