import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import api from "../../api/api";

const ResetPass = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleReset = (e) => {
    e.preventDefault();
    if (email.trim() === "") {
      return toast.error("Enter Valid Email");
    }

    setLoading(true);

    api({
      method: "POST",
      url: "/api/password_reset/",
      data: { email },
    })
      .then((res) => {
        console.log(res.data);
        toast.success("Token Has Been To Email");
        navigate("/reset-password/confirm", { replace: true });
      })
      .catch((err) => {
        console.log(err.response);
        toast.error(
          err?.response?.data?.detail || "Failed To Send Token On This Email"
        );
      })
      .finally(() => setLoading(false));
  };

  return (
    <section className="flex flex-col items-center justify-center w-screen min-h-[88vh] bg-gray-200 text-gray-700">
      <h1 className="font-bold text-2xl">Reset Password</h1>
      <form
        className="flex flex-col bg-white rounded shadow-lg p-7 mt-12 w-full md:w-[60%] lg:w-[30%]"
        onSubmit={handleReset}
      >
        <label className="font-semibold text-xs mb-1" htmlFor="usernameField">
          Email
        </label>
        <input
          type="email"
          placeholder="Type here"
          className="input w-full bg-gray-100"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          autoComplete="on"
        />

        {!loading ? (
          <button
            type="submit"
            className="flex items-center justify-center h-12 px-6 w-full bg-blue-600 mt-8 rounded font-semibold text-sm text-blue-100 hover:bg-blue-700"
          >
            Send Token
          </button>
        ) : (
          <button
            disabled={loading}
            className="btn flex items-center justify-center h-12 px-6 w-full bg-blue-600 mt-8 rounded font-semibold text-sm text-blue-100 loading"
          >
            Loading...
          </button>
        )}
        <div className="flex mt-6 justify-center text-xs gap-2">
          <span>Go back to</span>
          <Link to="/login" className="text-blue-400 hover:text-blue-500">
            Login
          </Link>
        </div>
      </form>
    </section>
  );
};

export default ResetPass;
