import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import api from "../../api/api";
import { logout, setUser } from "../../redux/user/userSlice";
import "./style.css";

const Navbar = () => {
  const dispatch = useDispatch();
  const { role, token, user } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);

  const handleLogout = () => {
    console.log("Logout");
    if (loading) {
      return;
    }
    setLoading(true);

    api({
      method: "GET",
      url: "/api/logout",
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => {
        console.log(res.data);
        dispatch(logout());
        toast.success("Logged Out Successfully");
      })
      .catch((err) => {
        console.log(err.response.data);
        dispatch(logout());
        toast.success("Logged Out Successfully");
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    api({
      method: "GET",
      url: "/api/user_details",
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => {
        // console.log(res.data);
        dispatch(setUser(res.data));
      })
      .catch((err) => {
        console.log(err.response.data);
        if (err.response?.data?.detail === "Invalid token.") {
          dispatch(logout());
          return toast.error("Token Expired Please Login Again");
        }
      });
  }, [dispatch, token]);

  return (
    <header className="lg:px-16 px-6 bg-white flex flex-wrap items-center lg:py-0 py-4 shadow-lg shadow-blue-100 sticky top-0 z-20">
      <div className="flex-1 flex justify-between items-center">
        <Link
          to="/"
          className="font-semibold text-lg flex justify-center items-center gap-1"
        >
          <img className="w-12 h-12" src="/images/enr.png" alt="EnR brand" />
          <div className="divider divider-horizontal mx-2 lg:mx-4 before:bg-gray-200 after:bg-gray-200 py-4"></div>
          <img
            className="w-32 md:w-40 py-2"
            src="/images/wheelz_logo2.png"
            // src="/images/wheelz_logo.png"
            alt="EnR Wheelz"
          />
        </Link>
      </div>

      <label htmlFor="menu-toggle" className="pointer-cursor lg:hidden block">
        <svg
          className="fill-current text-gray-900"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
        >
          <title>menu</title>
          <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
        </svg>
      </label>
      <input className="hidden" type="checkbox" id="menu-toggle" />

      <div
        className="hidden lg:flex lg:items-center lg:w-auto w-full"
        id="menu"
      >
        <nav>
          <ul className="lg:flex items-center justify-between text-base text-gray-700 pt-4 lg:pt-0">
            <li>
              <Link
                to="/"
                className="lg:p-4 py-3 px-0 block border-b-2 border-transparent hover:text-indigo-400 hover:font-medium"
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/profile"
                className="lg:p-4 py-3 px-0 block border-b-2 border-transparent hover:text-indigo-400 hover:font-medium"
              >
                Profile
              </Link>
            </li>
            {role && role === "Admin" ? (
              <>
                <li>
                  <Link
                    to="/customer"
                    className="lg:p-4 py-3 px-0 block border-b-2 border-transparent hover:text-indigo-400 hover:font-medium"
                  >
                    Customer
                  </Link>
                </li>
                <li>
                  <Link
                    to="/assign"
                    className="lg:p-4 py-3 px-0 block border-b-2 border-transparent hover:text-indigo-400 hover:font-medium"
                  >
                    Assign
                  </Link>
                </li>
                <li>
                  <Link
                    to="/requests"
                    className="lg:p-4 py-3 px-0 block border-b-2 border-transparent hover:text-indigo-400 hover:font-medium"
                  >
                    Requests
                  </Link>
                </li>
                <li>
                  <Link
                    to="/reports"
                    className="lg:p-4 py-3 px-0 block border-b-2 border-transparent hover:text-indigo-400 hover:font-medium"
                  >
                    Reports
                  </Link>
                </li>
              </>
            ) : (
              <>
                <li>
                  <Link
                    to="/delivered"
                    className="lg:p-4 py-3 px-0 block border-b-2 border-transparent hover:text-indigo-400 hover:font-medium"
                  >
                    Parcel
                  </Link>
                </li>
                <li>
                  <Link
                    to="/assigned"
                    className="lg:p-4 py-3 px-0 block border-b-2 border-transparent hover:text-indigo-400 hover:font-medium"
                  >
                    Assigned
                  </Link>
                </li>
              </>
            )}
            <li>
              <Link
                onClick={handleLogout}
                className="lg:p-4 py-3 px-0 block border-b-2 border-transparent hover:text-indigo-400 hover:font-medium"
              >
                Logout
              </Link>
            </li>
            <li className="avatar lg:px-4 lg:py-0 py-3 px-0 hidden lg:block">
              <div className="w-11 rounded-full">
                <img
                  src={
                    user?.ProfilePic || "https://placeimg.com/192/192/people"
                  }
                  alt="avatar"
                />
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Navbar;
