import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import api from "../../api/api";
import { logout } from "../../redux/user/userSlice";

const UpdatePass = () => {
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [cNewPassword, setCNewPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const { token } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const handleLogin = (e) => {
    e.preventDefault();

    if (newPassword !== cNewPassword) {
      toast.error("New password and Confirm New Password Not Matching");
      return;
    }

    setLoading(true);
    api({
      method: "POST",
      url: "/api/change_password",
      data: { current_password: password, new_password: newPassword },
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => {
        console.log(res.data);
        toast.success("Password Updated Successfully Please Login Again");
        dispatch(logout());
      })
      .catch((err) => {
        console.log(err.response);
        toast.error(err?.response?.data?.detail || "Failed To Update Password");
      })
      .finally(() => setLoading(false));
  };

  return (
    <section className="flex flex-col items-center justify-center w-screen min-h-[80vh] bg-gray-200 text-gray-700">
      <h1 className="font-bold text-2xl">Update Password</h1>
      <form
        className="flex flex-col bg-white rounded shadow-lg p-7 mt-12 w-full md:w-[60%] lg:w-[30%]"
        onSubmit={handleLogin}
      >
        <label className="font-semibold text-xs mb-1" htmlFor="usernameField">
          Old Password
        </label>
        <input
          type="password"
          placeholder="Type here"
          className="input w-full bg-gray-100"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          autoComplete="on"
        />
        <label
          className="font-semibold text-xs mt-3 mb-1"
          htmlFor="passwordField"
        >
          New Password
        </label>
        <input
          type="password"
          placeholder="Type here"
          className="input w-full bg-gray-100"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          autoComplete="on"
        />
        <label
          className="font-semibold text-xs mt-3 mb-1"
          htmlFor="npasswordField"
        >
          Confirm New Password
        </label>
        <input
          type="password"
          placeholder="Type here"
          className="input w-full bg-gray-100"
          value={cNewPassword}
          onChange={(e) => setCNewPassword(e.target.value)}
          autoComplete="on"
        />

        {!loading ? (
          <button
            type="submit"
            className="flex items-center justify-center h-12 px-6 w-full bg-blue-600 mt-8 rounded font-semibold text-sm text-blue-100 hover:bg-blue-700"
          >
            Update Password
          </button>
        ) : (
          <button
            disabled={loading}
            className="btn flex items-center justify-center h-12 px-6 w-full bg-blue-600 mt-8 rounded font-semibold text-sm text-blue-100 loading"
          >
            Loading...
          </button>
        )}

        <div className="mt-4">
          <h3 className="font-semibold text-sm mb-1">
            Password should contains at least:
          </h3>
          <ul className="text-xs">
            <li>1. One Character</li>
            <li>2. One digit</li>
            <li>3. One special character</li>
            <li>4. Minimum length be 6.</li>
          </ul>
        </div>
      </form>
    </section>
  );
};

export default UpdatePass;
