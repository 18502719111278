import React, { useLayoutEffect, useState } from "react";
import { IoCloseCircle } from "react-icons/io5";
import AddBranch from "./AddBranch";
import BranchTable from "./BranchTable";

const Branch = () => {
  const [openBranchModal, setOpenBranchModal] = useState(false);

  useLayoutEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <section className="flex flex-col items-center justify-center w-screen min-h-[70vh] text-gray-700 border-2 border-slate-200 py-2">
      <h3 className="text-center text-xl font-semibold ">Branch</h3>
      <div className="flex justify-center bg-white w-full md:w-[60%] lg:w-[40%] gap-2">
        <button
          onClick={(e) => setOpenBranchModal(!openBranchModal)}
          className="flex items-center justify-center h-12 px-6 w-full bg-blue-600 mt-4 rounded font-semibold text-sm text-blue-100 hover:bg-blue-700"
        >
          Add Branch
        </button>
      </div>
      {/* <AddBranch /> */}
      {/* <h3 className="text-center text-xl font-semibold mt-6 mb-4">
        Branch List
      </h3> */}
      <BranchTable />

      {/* Modal 2 */}
      <input
        type="checkbox"
        id="my-modal3"
        className="modal-toggle"
        checked={openBranchModal}
        readOnly
      />
      <div key={`modal-branch`} className="modal">
        <div className="modal-box relative overflow-scroll rounded-none p-4">
          <div className="modal-action absolute -top-3 right-2 text-red-600 cursor-pointer">
            <IoCloseCircle
              htmlFor="my-modal3"
              size={28}
              onClick={(e) => setOpenBranchModal(!openBranchModal)}
            />
            {/* <button
              onClick={(e) => setOpenModal(!openModal)}
              htmlFor="my-modal"
              className="p-4 rounded-full bg-blue-400"
            >
              X
            </button> */}
          </div>
          <AddBranch />
        </div>
      </div>
    </section>
  );
};

export default Branch;
