import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import api from "../../api/api";
// import { logout, setUser } from "../../redux/user/userSlice";
import ReportCard from "../Reports/ReportCard";
import Form from "./Form";
import MarkOutCard from "./MarkOutCard";
import moment from "moment";

const Home = () => {
  const [todayReport, setTodayReport] = useState(null);
  const [refreshComponent, setRefreshComponent] = useState(false);
  const { token } = useSelector((state) => state.user);
  // const dispatch = useDispatch();

  useEffect(() => {
    api({
      method: "GET",
      url: `/api/get_today_package/${
        moment(new Date()).toISOString(true).split("T")[0]
      }`,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => {
        console.log("/api/get_today_package/ ", res.data);
        setTodayReport(res.data);
      })
      .catch((err) => {
        console.log(err.response.data);
        if (err.response?.data?.detail === "Invalid token.") {
          return;
        }
        toast.error("Failed To Get Driver Details");

        // For Fixing Dynamic Errors generated by django sql
        // const errStr = [];
        // for (const data of Object.entries(errorObj)) {
        //   if (Array.isArray(data[1])) {
        //     errStr.push(`${data[0]}: ${data[1][0]}`);
        //   } else {
        //     errStr.push(data[1]);
        //   }
        // }
        // console.log(errStr.join("\n"));
      });
  }, [token, refreshComponent]);

  return (
    <section className="flex flex-col items-center w-screen min-h-[80vh] text-gray-700 mb-4">
      <h1 className="w-full font-bold text-2xl mt-4 text-center mb-2">
        Branded WheelzPro
      </h1>
      {todayReport && todayReport.length !== 0 && (
        <div>
          <p className="text-center">Enter Delivery Details</p>
        </div>
      )}

      {todayReport && todayReport.length !== 0 ? (
        <>
          {todayReport.map((tr) => (
            <div key={tr.id} className="w-full flex justify-center">
              {tr.package.length !== 0 ? (
                <>
                  {tr.package &&
                    tr.package.map((pack) => {
                      return (
                        <>
                          {pack?.IsDelivered ? (
                            <ReportCard key={pack.id} packageData={pack} />
                          ) : (
                            <MarkOutCard
                              key={pack.id}
                              pack={pack}
                              setRefreshComponent={setRefreshComponent}
                              refreshComponent={refreshComponent}
                            />
                          )}
                        </>
                      );
                    })}
                </>
              ) : (
                <Form
                  tr={tr}
                  setRefreshComponent={setRefreshComponent}
                  refreshComponent={refreshComponent}
                />
              )}
            </div>
          ))}
        </>
      ) : (
        <>
          <h3 className="text-center">No Delivery Assigned</h3>
        </>
      )}

      {/* Legacy Code */}
      {/* {todayReport && todayReport.length !== 0 ? (
        <>
          {" "}
          {todayReport && todayReport[0]?.IsDelivered ? (
            <ReportCard packageData={todayReport[0]} />
          ) : (
            <MarkOutCard
              packageId={todayReport[0].id}
              setRefreshComponent={setRefreshComponent}
              refreshComponent={refreshComponent}
            />
          )}
        </>
      ) : (
        <>
          <Form
            setRefreshComponent={setRefreshComponent}
            refreshComponent={refreshComponent}
          />
        </>
      )} */}
    </section>
  );
};

export default Home;
