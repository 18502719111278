import React from "react";

const ReportCard = ({ packageData }) => {
  const reasonObj = JSON.parse(packageData?.PendingReason);
  return (
    <div className="flex flex-col p-7 mt-5 w-full md:w-[60%] lg:w-[30%] shadow-lg rounded border-2 border-gray-200">
      <div>
        <h3 className="text-center font-semibold">
          {" "}
          {packageData?.DeliveryDate} Report
        </h3>
      </div>
      <div className="divider m-0"></div>
      <div>
        <p className="font-semibold mb-1">Customer: {packageData?.Customer}</p>
        <p className="font-semibold mb-1">Branch: {packageData?.Branch}</p>
        <p className="font-semibold mb-1">
          Start Time: {new Date(packageData?.AddedDate).toLocaleTimeString()}
        </p>
        <p className="font-semibold mb-1">
          End Time: {new Date(packageData?.UpdateDate).toLocaleTimeString()}
        </p>
        <p className="font-semibold mb-1">Shift: {packageData?.Shift}</p>
        <p className="font-semibold mb-1">
          Total Orders Picked: {packageData?.PackageTaken}
        </p>
        <p className="font-semibold mb-1">
          Total Orders Delivered: {packageData?.PackageDelivered}
        </p>
      </div>

      {packageData?.PackagePending !== 0 && (
        <>
          {" "}
          <div className="divider m-0"></div>
          <div>
            <p className="font-semibold mb-1">Reason for Return</p>
            {reasonObj?.reason ? (
              <>
                <p>
                  {" "}
                  <span className="font-semibold mb-1"> Reason:</span>{" "}
                  {reasonObj?.reason}
                </p>
                {reasonObj?.reason === "Other" && (
                  <p>
                    <span className="font-semibold mb-1">Other:</span>{" "}
                    {reasonObj?.reasonOther}
                  </p>
                )}
                {reasonObj?.rtPackages && (
                  <p className="mb-1">
                    <div className="font-semibold">
                      What have you done with any of the returned packages?
                    </div>{" "}
                    {reasonObj?.rtPackages}
                  </p>
                )}
                <p>
                  <span className="font-semibold mb-1">Notes:</span>{" "}
                  {reasonObj?.notes}
                </p>
              </>
            ) : (
              <p> No Notes Provided</p>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ReportCard;
