import React, { useEffect, useLayoutEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import api from "../../../api/api";
import ReqCard from "./ReqCard";

const Requests = () => {
  const [requests, setRequests] = useState([]);
  const { token } = useSelector((state) => state.user);

  useEffect(() => {
    api({
      method: "GET",
      url: "/api/driver_register_request",
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => {
        // console.log(res.data);
        setRequests(res.data);
      })
      .catch((err) => {
        console.log(err.response.data);
        if (err.response?.data?.detail === "Invalid token.") {
          return;
        }
        toast.error("Failed To Get Requests");
      });
  }, [token]);

  useLayoutEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <section className="flex p-1 lg:p-5 gap-4 flex-wrap justify-center items-start w-screen min-h-[77.5vh] text-gray-700 mb-4 box-border">
      {requests && requests.length !== 0 ? (
        requests.map((reqData) => (
          <ReqCard key={reqData?.id} reqData={reqData} />
        ))
      ) : (
        <h3>No requests found</h3>
      )}
    </section>
  );
};

export default Requests;
