import React, { useState, useEffect, useLayoutEffect } from "react";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import api from "../../api/api";
import "./assigned.css";

const Assigned = () => {
  //   const [refreshComponent, setRefreshComponent] = useState(false);
  const [assignArr, setAssignArr] = useState(null);
  const [loading, setLoading] = useState(false);
  const { token } = useSelector((state) => state.user);
  const [activeTab, setActiveTab] = useState(0);

  const getData = (token, activeTab) => {
    setLoading(true);
    api({
      method: "GET",
      url:
        activeTab === 0
          ? `/api/driver_package_assigned`
          : `/api/driver_package_assigned_pending`,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => {
        console.log("driver_package_assigned: ", res.data);
        setAssignArr(res.data);
      })
      .catch((err) => {
        console.log(err.response.data);
        setAssignArr([]);
        if (err.response?.data?.detail === "Invalid token.") {
          return;
        }
        toast.error("Failed To Get Data");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getData(token, activeTab);
  }, [token, activeTab]);

  useLayoutEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  if (loading) {
    return (
      <section>
        <section className="flex p-1 lg:p-5 gap-x-4 gap-y-1  flex-wrap justify-center items-start w-screen min-h-[77.5vh] text-gray-700 mb-4">
          <h3 className="text-center font-semibold">Loading...</h3>
        </section>
      </section>
    );
  }

  return (
    <section className="mt-2">
      <div className="flex justify-center space-x-6 border-b">
        <button
          className={`py-2  border-b-4 transition-colors duration-300 uppercase  ${
            activeTab === 0
              ? "border-teal-500 font-semibold"
              : "border-transparent hover:border-gray-200"
          } `}
          onClick={() => setActiveTab(0)}
        >
          Scheduled
        </button>
        <div className="divider divider-horizontal mx-2 lg:mx-4 before:bg-gray-200 after:bg-gray-200 py-2"></div>
        <button
          className={`py-2 border-b-4 transition-colors duration-300 uppercase  ${
            activeTab === 1
              ? "border-teal-500 font-semibold"
              : "border-transparent hover:border-gray-200"
          }`}
          onClick={() => setActiveTab(1)}
        >
          Missed
        </button>
      </div>
      {activeTab === 0 ? (
        <section className="flex items-center justify-center min-h-[77vh]">
          {assignArr && assignArr.length !== 0 ? (
            <div className="container w-full lg:w-[90%] self-start">
              <table className="w-full flex flex-row flex-no-wrap sm:bg-white rounded-lg overflow-hidden sm:shadow-lg my-5 mytable">
                <thead className="text-white mythead">
                  {assignArr &&
                    assignArr.map((dat, i) => (
                      <tr
                        key={`theader-${i}`}
                        className="bg-teal-400 flex flex-col flex-no wrap sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0"
                      >
                        <th className="p-3 text-left myth cursor-default">
                          Date
                        </th>
                        <th className="p-3 text-left myth cursor-default">
                          Driver Name
                        </th>
                        <th className="p-3 text-left myth cursor-default">
                          Customer
                        </th>
                        <th className="p-3 text-left myth cursor-default">
                          Branch
                        </th>
                      </tr>
                    ))}
                </thead>
                <tbody className="flex-1 sm:flex-none">
                  {assignArr &&
                    assignArr.map((dat) => (
                      <tr
                        key={dat.id}
                        className="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0"
                      >
                        <td className="border-grey-light border hover:bg-gray-100 p-3 mytd cursor-default">
                          {dat.Date}
                        </td>
                        <td className="border-grey-light border hover:bg-gray-100 p-3 mytd cursor-default">
                          {dat.DriverName}
                        </td>
                        <td className="border-grey-light border hover:bg-gray-100 p-3 mytd cursor-default">
                          {dat.CustomerName}
                        </td>
                        <td className="border-grey-light border hover:bg-gray-100 p-3 mytd cursor-default">
                          {dat.BranchName}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          ) : (
            <h3 className="text-center m-4 self-start">No Data Found</h3>
          )}
        </section>
      ) : (
        <section className="flex items-center justify-center min-h-[77vh]">
          {assignArr && assignArr.length !== 0 ? (
            <div className="container w-full lg:w-[90%] self-start">
              <table className="w-full flex flex-row flex-no-wrap sm:bg-white rounded-lg overflow-hidden sm:shadow-lg my-5 mytable">
                <thead className="text-white mythead">
                  {assignArr &&
                    assignArr.map((dat, i) => (
                      <tr
                        key={`theader-${i}`}
                        className="bg-teal-400 flex flex-col flex-no wrap sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0"
                      >
                        <th className="p-3 text-left myth cursor-default">
                          Date
                        </th>
                        <th className="p-3 text-left myth cursor-default">
                          Driver Name
                        </th>
                        <th className="p-3 text-left myth cursor-default">
                          Customer
                        </th>
                        <th className="p-3 text-left myth cursor-default">
                          Branch
                        </th>
                        <th className="p-3 text-left myth cursor-default">
                          Status
                        </th>
                      </tr>
                    ))}
                </thead>
                <tbody className="flex-1 sm:flex-none">
                  {assignArr &&
                    assignArr.map((dat) => (
                      <tr
                        key={dat.id}
                        className="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0"
                      >
                        <td className="border-grey-light border hover:bg-gray-100 p-3 mytd cursor-default">
                          {dat.Date}
                        </td>
                        <td className="border-grey-light border hover:bg-gray-100 p-3 mytd cursor-default">
                          {dat.DriverName}
                        </td>
                        <td className="border-grey-light border hover:bg-gray-100 p-3 mytd cursor-default">
                          {dat.CustomerName}
                        </td>
                        <td className="border-grey-light border hover:bg-gray-100 p-3 mytd cursor-default">
                          {dat.BranchName}
                        </td>
                        <td className="border-grey-light border hover:bg-gray-100 p-3 mytd cursor-default">
                          Not Accepted
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          ) : (
            <h3 className="text-center m-4 self-start">No Data Found</h3>
          )}
        </section>
      )}
    </section>
  );
};

export default Assigned;
