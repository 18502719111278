import React from "react";

const InfoSection = () => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 justify-center items-center w-screen py-4">
      <section className="flex justify-center items-center">
        <div className="w-3/4">
          <h2 className="text-2xl lg:text-4xl font-bold mb-4 leading-loose">
            Flexible Courier Jobs <br />& Great Rates
          </h2>
          <p className="font-medium leading-loose">
            WheelzPro is dedicated to offering regular and flexible courier
            jobs. The platform connects you to customers in the area who have
            parcels to move, it treats its drivers ethically with fair rates of
            pay which mean you’re never undervalued for your service. And the
            fast automated payment system means you’re not waiting weeks to get
            paid. It’s free to register and there are no monthly costs.
          </p>
        </div>
      </section>
      <section className="flex justify-center items-center">
        <img
          className="w-3/4"
          src="/images/delivery_img.png"
          alt="delivery_info"
        />
      </section>
    </div>
  );
};

export default InfoSection;
