import React, { useState } from "react";
import { IoCloseCircle } from "react-icons/io5";
import UpdateForm from "./UpdateForm";
import { Link } from "react-router-dom";
import { toast } from "react-hot-toast";

const TableCell = ({ row }) => {
  const [openAssignModal, setOpenAssignModal] = useState(false);
  const [openAssignModalDetails, setOpenAssignModalDetails] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <div>
      {row.IsAccept ? (
        <div
          onClick={(e) => setOpenAssignModalDetails(!openAssignModalDetails)}
          className="font-semibold uppercase text-blue-700 py-1 cursor-pointer"
        >
          Details
        </div>
      ) : (
        <div
          onClick={(e) => setOpenAssignModal(!openAssignModal)}
          className="font-semibold uppercase text-blue-700 py-1 cursor-pointer"
        >
          Reschedule
        </div>
      )}

      {/* Modal 2 */}
      <input
        type="checkbox"
        id="my-modal"
        className="modal-toggle"
        checked={openAssignModal}
        readOnly
      />
      <div key={`modal-branch-${row.id}`} className="modal">
        <div className="modal-box relative overflow-scroll rounded-none px-4 py-6">
          <div className="modal-action absolute -top-3 right-2 text-red-600 cursor-pointer">
            <IoCloseCircle
              htmlFor="my-modal"
              size={28}
              onClick={(e) => {
                if (loading) {
                  toast.error("Wait While Loading");
                  return;
                }
                setOpenAssignModal(!openAssignModal);
              }}
            />
            {/* <button
      onClick={(e) => setOpenModal(!openModal)}
      htmlFor="my-modal"
      className="p-4 rounded-full bg-blue-400"
    >
      X
    </button> */}
          </div>
          <UpdateForm
            assignData={row}
            loading={loading}
            setLoading={setLoading}
          />
        </div>
      </div>

      {/* Modal Details */}
      <input
        type="checkbox"
        id="my-modal-Details"
        className="modal-toggle"
        checked={openAssignModalDetails}
        readOnly
      />
      <div key={`modalDetails-${row.id}`} className="modal">
        <div className="modal-box relative overflow-scroll rounded-none p-4">
          <div className="modal-action absolute -top-4 right-2 text-red-600 cursor-pointer">
            <IoCloseCircle
              htmlFor="my-modal-Details"
              size={28}
              onClick={(e) => {
                setOpenAssignModalDetails(!openAssignModalDetails);
              }}
            />

            {/* <button
      onClick={(e) => setOpenModal(!openModal)}
      htmlFor="my-modal"
      className="p-4 rounded-full bg-blue-400"
    >
      X
    </button> */}
          </div>
          {row &&
            row.package.length !== 0 &&
            row.package.map((dat) => (
              <div className="mt-6">
                <div className="divider m-0"></div>
                <div>
                  <h3 className="text-center font-semibold">
                    {" "}
                    {dat?.DeliveryDate} Report
                  </h3>
                </div>
                <div className="divider m-0"></div>
                <div>
                  <p className="font-semibold mb-1">
                    Status: {dat?.IsDelivered ? "Delivered" : "Pending"}
                  </p>
                </div>
                <div>
                  <p className="font-semibold mb-1">Shift: {dat?.Shift}</p>
                </div>
                <div>
                  <p className="font-semibold mb-1">
                    Total Packages Taken: {dat?.PackageTaken}
                  </p>
                </div>
                {dat.IsDelivered && (
                  <>
                    <div>
                      <p className="font-semibold mb-1">
                        Package Delivered: {dat?.PackageDelivered}
                      </p>
                    </div>
                  </>
                )}
                <Link
                  to={`/user-report/${row.DriverId}`}
                  className="font-semibold text-blue-700 py-1 cursor-pointer"
                >
                  More Details
                </Link>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default TableCell;
