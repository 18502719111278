import React, { useState, useEffect, useLayoutEffect } from "react";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import api from "../../api/api";
import MarkOutCard from "../Home/MarkOutCard";
import PendingCard from "./PendingCard";
import ReportCard from "./ReportCard";

const Reports = () => {
  const [refreshComponent, setRefreshComponent] = useState(false);
  const [packageDataArr, setPackageDataArr] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const {
    token,
    user: { id },
  } = useSelector((state) => state.user);

  const getData = (userId, token, activeTab) => {
    setLoading(true);
    api({
      method: "GET",
      url:
        activeTab === 0
          ? `/api/driver_delivered_package/${userId}`
          : `/api/driver_delivered_package_pending/${userId}`,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => {
        console.log("driver_delivered_package_pending", res.data);
        setPackageDataArr(res.data);
      })
      .catch((err) => {
        console.log(err.response.data);
        setPackageDataArr([]);
        if (err.response?.data?.detail === "Invalid token.") {
          return;
        }
        toast.error("Failed To Get Data");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getData(id, token, activeTab);
  }, [id, token, activeTab]);

  useLayoutEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  if (loading) {
    return (
      <section>
        <section className="flex p-1 lg:p-5 gap-x-4 gap-y-1  flex-wrap justify-center items-start w-screen min-h-[77.5vh] text-gray-700 mb-4">
          <h3 className="text-center font-semibold">Loading...</h3>
        </section>
      </section>
    );
  }

  return (
    <section className="mt-2">
      <div className="flex justify-center space-x-6 border-b">
        <button
          className={`py-2  border-b-4 transition-colors duration-300 uppercase ${
            activeTab === 0
              ? "border-teal-500 font-semibold"
              : "border-transparent hover:border-gray-200"
          } `}
          onClick={() => setActiveTab(0)}
        >
          Completed
        </button>
        <div className="divider divider-horizontal mx-2 lg:mx-4 before:bg-gray-200 after:bg-gray-200 py-2"></div>
        <button
          className={`py-2 border-b-4 transition-colors duration-300 uppercase ${
            activeTab === 1
              ? "border-teal-500 font-semibold"
              : "border-transparent hover:border-gray-200"
          }`}
          onClick={() => setActiveTab(1)}
        >
          Pending
        </button>
      </div>
      {activeTab === 0 ? (
        <section className="flex p-1 lg:p-5 gap-x-4 gap-y-1  flex-wrap justify-center items-start w-screen min-h-[77.5vh] text-gray-700 mb-4">
          {packageDataArr && packageDataArr.length !== 0 ? (
            packageDataArr.map((dat) =>
              dat.IsDelivered ? (
                <ReportCard key={dat.id} packageData={dat} />
              ) : (
                <MarkOutCard
                  key={dat.id}
                  packageId={dat.id}
                  setRefreshComponent={setRefreshComponent}
                  refreshComponent={refreshComponent}
                />
              )
            )
          ) : (
            <h3 className="text-center">No Completed Reports Found</h3>
          )}
        </section>
      ) : (
        <section className="flex p-1 lg:p-5 gap-x-4 gap-y-1  flex-wrap justify-center items-start w-screen min-h-[77.5vh] text-gray-700 mb-4">
          {packageDataArr && packageDataArr.length !== 0 ? (
            packageDataArr.map((dat) =>
              dat.IsDelivered ? (
                <ReportCard key={dat.id} packageData={dat} />
              ) : (
                <PendingCard key={dat.id} packageData={dat} />
              )
            )
          ) : (
            <h3 className="text-center">No Pending Reports Found</h3>
          )}
        </section>
      )}
    </section>
  );
};

export default Reports;
