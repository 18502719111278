import * as xlsx from "xlsx";

export function downloadXLSX(data) {
  console.log("PD: ", data);

  if (!Array.isArray(data) && data.length === 0) {
    return;
  }
  const newArr = data.map((d) => {
    const {
      DeliveryDate,
      IsDelivered,
      DriverName,
      Customer,
      Branch,
      Shift,
      AddedDate,
      UpdateDate,
      Vehicle,
      VehicleNumber,
      FuelType,
      PackageTaken,
      PackagePending,
      PackageConfirmation,
      reason,
      reasonOther,
      rtPackages,
      appCleared,
      notes,
      id,
    } = d;

    const queObj = {};
    if (
      Array.isArray(PackageConfirmation) &&
      PackageConfirmation.length !== 0
    ) {
      for (let i = 0; i < PackageConfirmation.length; i++) {
        queObj[PackageConfirmation[i].que] = PackageConfirmation[i].ans;
      }
    }

    const newDat = {
      id: id,
      "Delivery Date": DeliveryDate,
      Status: IsDelivered ? "Completed" : "Pending",
      "Driver Name": DriverName,
      Customer: Customer,
      Branch: Branch,
      Shift: Shift,
      "Start Time": new Date(AddedDate).toLocaleTimeString(),
      "End Time": UpdateDate ? new Date(UpdateDate).toLocaleTimeString() : "",
      Vehicle: Vehicle,
      "Vehicle Number": VehicleNumber,
      "Fuel Type": FuelType,
      "Package Taken": PackageTaken,
      "Package Pending": PackagePending,
      Reason: reason === "Other" ? reasonOther : reason,
      Returned: rtPackages ? rtPackages : "",
      "App Cleared": appCleared,
      Notes: notes,
      ...queObj,
    };
    return newDat;
  });

  console.log("PD: ", newArr);
  const ws = xlsx.utils.json_to_sheet(newArr);
  const wb = xlsx.utils.book_new();
  xlsx.utils.book_append_sheet(wb, ws, "People");
  xlsx.writeFile(
    wb,
    `report_${new Date().toLocaleDateString()}_${new Date().toLocaleTimeString()}.xlsx`
  );
}
