import React, { useState } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import api from "../../api/api";
import moment from "moment";

const queData = [
  {
    que: "I acknowledge that the information provided is correct",
    options: ["Yes", "No"],
    ans: "",
  },
  {
    que: "I have a full UK driver's license",
    options: ["Yes", "No"],
    ans: "",
  },
  {
    que: "My vehicle is taxed",
    options: ["Yes", "No"],
    ans: "",
  },
  {
    que: "My vehicle has a valid MOT",
    options: ["Yes", "No"],
    ans: "",
  },
  {
    que: "I am sober and not under the influence of alcohol / drugs",
    options: ["Yes", "No"],
    ans: "",
  },
  {
    que: "I am insured for Goods in Transit and Public Liability Insurance",
    options: ["Yes", "No"],
    ans: "",
  },
  {
    que: "I have checked my vehicle for compliance",
    options: ["Yes", "No"],
    ans: "",
  },
];

const Form = ({ setRefreshComponent, refreshComponent, tr }) => {
  const [date, setDate] = useState(new Date(tr.Date) || new Date());
  const [packagesTaken, setPackagesTaken] = useState("");
  const [customer, setCustomer] = useState(tr.CustomerName || "");
  const [branch, setBranch] = useState(tr.BranchName || "");
  const [shift, setShift] = useState("");
  const [que, setQue] = useState(queData);
  const [loading, setLoading] = useState(false);

  const { token } = useSelector((state) => state.user);

  // console.log("tr", tr);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (packagesTaken === "" || shift === "") {
      toast.error("Fill All Fields");
      return;
    }
    const validation = que.every((item) => item.ans);
    if (!validation) {
      toast.error("Fill all fields");
      return;
    }

    // console.log(validation);

    const formattedDate = moment(date).toISOString(true).split("T")[0];

    console.log({
      DeliveryDate: formattedDate,
      PackageTaken: Number(packagesTaken),
      PackageConfirmation: JSON.stringify(que),
      Shift: shift,
      Customer: customer,
      Branch: branch,
    });

    setLoading(true);

    api({
      method: "POST",
      url: `/api/start_delivery/${tr.id}`,
      headers: {
        Authorization: `Token ${token}`,
      },
      data: {
        // DeliveryDate: formattedDate,
        PackageTaken: Number(packagesTaken),
        PackageConfirmation: JSON.stringify(que),
        Shift: shift,
        // Customer: customer,
        // Branch: branch,
      },
    })
      .then((res) => {
        // console.log(res.data);
        setRefreshComponent(!refreshComponent);
        toast.success("Delivery Details Added Successfully");
      })
      .catch((err) => {
        console.log(err.response.data);
        if (err.response?.data?.detail === "Invalid token.") {
          return;
        }
        toast.error(err.response?.data?.detail || "Failed to submit form");
      })
      .finally(() => setLoading(false));
  };

  return (
    <form
      className="flex flex-col p-7 mt-5 w-full md:w-[60%] lg:w-[30%] shadow rounded border-2 border-gray-200"
      onSubmit={handleSubmit}
    >
      <label className="font-semibold text-xs mb-1" htmlFor="customerField">
        Customer
      </label>
      <input
        type="text"
        id="customerField"
        name="customerField"
        placeholder="Type here"
        className="input w-full bg-gray-100"
        value={customer}
        onChange={(e) => setCustomer(e.target.value)}
        disabled
        required
      />
      <label className="font-semibold text-xs mt-3 mb-1" htmlFor="branchField">
        Branch
      </label>
      <input
        type="text"
        id="branchField"
        name="branchField"
        placeholder="Type here"
        className="input w-full bg-gray-100"
        value={branch}
        onChange={(e) => setBranch(e.target.value)}
        disabled
        required
      />
      <label className="font-semibold text-xs mt-3 mb-1" htmlFor="dateField">
        Date
      </label>
      <DatePicker
        className="input w-full bg-gray-100"
        selected={date}
        onChange={(date) => setDate(date)}
        disabled
        readOnly
      />

      <label className="font-semibold text-xs mt-3 mb-1" htmlFor="shiftField">
        Shift
      </label>
      <select
        className="select w-full bg-gray-100"
        id="shiftField"
        name="shiftField"
        value={shift}
        onChange={(e) => setShift(e.target.value)}
        required
      >
        <option disabled value="">
          Select Shift
        </option>
        <option value="Morning">Morning</option>
        <option value="Afternoon">Afternoon</option>
        <option value="Evening">Evening</option>
      </select>

      <label className="font-semibold text-xs mt-3 mb-1" htmlFor="ordersField">
        Packages Taken
      </label>
      <input
        type="number"
        id="ordersField"
        name="ordersField"
        placeholder="Type here"
        className="input w-full bg-gray-100 mb-3"
        value={packagesTaken}
        onChange={(e) => setPackagesTaken(e.target.value)}
        required
      />

      {/* Questions */}
      {queData.map((q, index) => (
        <div className="mb-3" key={`que-input-${index}`}>
          <label
            className="font-semibold text-xs mb-1"
            htmlFor={`radio-${index}`}
          >
            {q.que}
          </label>

          <div className="flex gap-2">
            {q.options.map((o, i) => (
              <label
                key={`q-input-${i}`}
                className="label cursor-pointer flex gap-2"
              >
                <input
                  type="radio"
                  name={`radio-${index}`}
                  className="radio checked:bg-blue-500"
                  checked={que[index].ans === o}
                  onChange={(e) => {
                    const newArr = que.map((q, i) => {
                      if (index === i) return { ...q, ans: o };
                      return q;
                    });
                    setQue(newArr);
                    // console.log(que);
                  }}
                />
                <span className="label-text">{o}</span>
              </label>
            ))}
          </div>
        </div>
      ))}

      {!loading ? (
        <button
          type="submit"
          disabled={loading}
          className="flex items-center justify-center h-12 px-6 w-full bg-blue-600 mt-8 rounded font-semibold text-sm text-blue-100 hover:bg-blue-700"
        >
          Submit
        </button>
      ) : (
        <button
          disabled={loading}
          className="btn flex items-center justify-center h-12 px-6 w-full bg-blue-600 mt-8 rounded font-semibold text-sm text-blue-100 loading"
        >
          Loading...
        </button>
      )}
    </form>
  );
};

export default Form;
