import React, { useEffect, useLayoutEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import api from "../../../api/api";
import FilterTable from "./FilterTable";
// import { logout, setUser } from "../../../redux/user/userSlice";
import Stats from "./Stats";
// import Table from "./Table";

const AdminHome = () => {
  const [activeUserList, setActiveUserList] = useState([]);
  const [nonActiveUserList, setNonActiveUserList] = useState([]);
  // const dispatch = useDispatch();
  const { token } = useSelector((state) => state.user);

  // useEffect(() => {
  //   api({
  //     method: "GET",
  //     url: "/api/user_details",
  //     headers: {
  //       Authorization: `Token ${token}`,
  //     },
  //   })
  //     .then((res) => {
  //       console.log(res.data);
  //       dispatch(setUser(res.data));
  //     })
  //     .catch((err) => {
  //       console.log(err.response.data);
  //       if (err.response?.data?.detail === "Invalid Token.") {
  //         toast.error("Token expired please login again");
  //         return dispatch(logout());
  //       }
  //     });
  // }, [dispatch, token]);

  useEffect(() => {
    api({
      method: "GET",
      url: "/api/driver_active",
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => {
        // console.log("driver_active", res.data);
        const newArr =
          res.data.map((dat) => {
            return {
              id: dat.id,
              name: `${dat.first_name} ${dat.last_name}`,
              Phone: dat.Phone,
              email: dat.email,
              UniqueId: dat.UniqueId,
              status: true,
            };
          }) || [];
        setActiveUserList(newArr);
      })
      .catch((err) => {
        console.log(err.response.data);
        if (err.response?.data?.detail === "Invalid token.") {
          return;
        }
        toast.error("Failed To Get Driver List");
      });
  }, [token]);

  useEffect(() => {
    api({
      method: "GET",
      url: "/api/driver_deactive",
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => {
        // console.log("driver_deactive", res.data);
        const newArr =
          res.data.map((dat) => {
            return {
              id: dat.id,
              name: `${dat.first_name} ${dat.last_name}`,
              Phone: dat.Phone,
              email: dat.email,
              UniqueId: dat.UniqueId,
              status: false,
            };
          }) || [];
        setNonActiveUserList(newArr);
      })
      .catch((err) => {
        console.log(err.response.data);
        if (err.response?.data?.detail === "Invalid token.") {
          return;
        }
      });
  }, [token]);

  useLayoutEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="min-h-[80vh]">
      {activeUserList && nonActiveUserList && (
        <Stats
          total={activeUserList.length + nonActiveUserList.length}
          active={activeUserList.length}
        />
      )}

      {activeUserList && activeUserList.length !== 0 ? (
        <FilterTable UserList={activeUserList} title="Active Driver List" />
      ) : (
        <>
          <h3 className="text-center text-xl font-semibold mt-6 mb-4">
            Active Driver List
          </h3>
          <h3 className="text-center my-2">No active users found</h3>
        </>
      )}

      {nonActiveUserList && nonActiveUserList.length !== 0 ? (
        <FilterTable
          UserList={nonActiveUserList}
          title="Deactive Driver List"
        />
      ) : (
        <>
          <h3 className="text-center text-xl font-semibold mt-6 mb-4">
            Deactive Driver List
          </h3>
          <h3 className="text-center my-2">No deactive users found</h3>
        </>
      )}
    </div>
  );
};

export default AdminHome;
