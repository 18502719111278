import React from "react";

const FrameSection = () => {
  return (
    <div className="w-full">
      <img className="w-full " src="/images/Frame.png" alt="frame" />
    </div>
  );
};

export default FrameSection;
