import React from "react";
import DataTable from "react-data-table-component";
import { MdOutlineClose } from "react-icons/md";
import QueModal from "./QueModal";
import { downloadXLSX } from "../../../utils/excelDL";

// const TableContent = ({ data, status, index }) => {
//   const reasonObj = JSON.parse(data?.PendingReason);
//   return (
//     <tr>
//       <th>
//         <label>{index + 1}</label>
//       </th>
//       <td>
//         <label>{status}</label>
//       </td>
//       <td>
//         <div className="flex items-center space-x-3">
//           <div>{data?.DeliveryDate}</div>
//         </div>
//       </td>
//       <td>
//         <label>{data?.DriverName}</label>
//       </td>
//       <td>
//         <label>{data?.Vehicle}</label>
//       </td>
//       <td>
//         <label>{data?.VehicleNumber}</label>
//       </td>
//       <td>
//         <label>{data?.FuelType}</label>
//       </td>
//       <td>
//         <div className="flex items-center space-x-3">
//           <div>{data?.Customer}</div>
//         </div>
//       </td>
//       <td>
//         <div className="flex items-center space-x-3">
//           <div>{data?.Branch}</div>
//         </div>
//       </td>
//       <td>
//         <div className="flex items-center space-x-3">
//           <div>{data?.Shift}</div>
//         </div>
//       </td>
//       <td>
//         <div className="flex items-center space-x-3">
//           <div>{new Date(data?.AddedDate).toLocaleTimeString()}</div>
//         </div>
//       </td>
//       <td>
//         <div className="flex items-center space-x-3">
//           <div>
//             {data.UpdateDate
//               ? new Date(data?.UpdateDate).toLocaleTimeString()
//               : ""}
//           </div>
//         </div>
//       </td>
//       <td>
//         <div className="flex items-center space-x-3">
//           <div>{data?.PackageTaken}</div>
//         </div>
//       </td>
//       <td>
//         <div className="flex items-center space-x-3">
//           <div>{data?.PackagePending}</div>
//         </div>
//       </td>
//       <td>
//         <div className="flex items-center space-x-3">
//           <div>
//             {reasonObj?.reason === "Other"
//               ? reasonObj?.reasonOther
//               : reasonObj?.reason}
//           </div>
//         </div>
//       </td>
//       <td>
//         <div className="flex items-center space-x-3">
//           <div>{reasonObj?.appCleared}</div>
//         </div>
//       </td>
//       <td>
//         <div className="flex items-center space-x-3">
//           <div>{reasonObj?.notes}</div>
//         </div>
//       </td>
//       <td></td>
//     </tr>
//   );
// };
const ReportTable = ({ packageData, loading }) => {
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  // const filteredItems = packageData.filter(
  //   (item) =>
  //     item.Customer &&
  //     item.Customer.toLowerCase().includes(filterText.toLowerCase())
  // );

  const re = new RegExp(filterText, "i");
  const filteredItems = packageData.filter((entry) =>
    Object.values(entry).some((val) => typeof val === "string" && val.match(re))
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };
    const handleDownloadExcel = (e) => {
      e.preventDefault();
      downloadXLSX(filteredItems);
    };

    return (
      <section className="flex flex-col md:flex-row justify-center items-center gap-4 w-full">
        <div className="border rounded overflow-hidden flex w-[90%] lg:w-1/2">
          <input
            type="text"
            className="px-4 py-2 w-full"
            placeholder="Search..."
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
          />
          <button
            className="flex items-center justify-center px-4 border-l"
            onClick={handleClear}
          >
            <MdOutlineClose />
          </button>
        </div>
        <button
          className="py-[0.6rem] px-6 bg-blue-600 rounded font-semibold text-sm text-blue-100 hover:bg-blue-700"
          onClick={handleDownloadExcel}
        >
          Download Report
        </button>
      </section>
    );
  }, [filterText, resetPaginationToggle, filteredItems]);

  const columns = [
    {
      name: "Delivery Date",
      selector: (row) => row.DeliveryDate,
      sortable: true,
      grow: 2,
    },
    {
      name: "Status",
      cell: (row) => {
        return (
          <div className="">{row.IsDelivered ? "Completed" : "Pending"}</div>
        );
      },
    },
    {
      name: "Driver Name",
      selector: (row) => row.DriverName,
      sortable: true,
      grow: 3,
    },
    {
      name: "Customer",
      selector: (row) => row.Customer,
      sortable: true,
      grow: 3,
    },
    {
      name: "Branch",
      selector: (row) => row.Branch,
      sortable: true,
      grow: 3,
    },
    {
      name: "Shift",
      selector: (row) => row.Shift,
      sortable: true,
      grow: 2,
    },
    {
      name: "Start Time",
      cell: (row) => {
        return (
          <div className="">
            {new Date(row?.AddedDate).toLocaleTimeString()}
          </div>
        );
      },
      grow: 2,
    },
    {
      name: "End Time",
      cell: (row) => {
        return (
          <div className="">
            {row.UpdateDate
              ? new Date(row?.UpdateDate).toLocaleTimeString()
              : ""}
          </div>
        );
      },
      grow: 2,
    },
    {
      name: "Vehicle",
      selector: (row) => row.Vehicle,
      sortable: true,
    },
    {
      name: "Vehicle Number",
      selector: (row) => row.VehicleNumber,
      sortable: true,
    },
    {
      name: "Fuel Type",
      selector: (row) => row.FuelType,
      sortable: true,
    },
    {
      name: "Acknowledgement",
      cell: (row) => {
        return <QueModal ques={row.PackageConfirmation} id={row.id} />;
      },
      grow: 2,
    },
    {
      name: "Package Taken",
      selector: (row) => row.PackageTaken,
      sortable: true,
    },
    {
      name: "Package Pending",
      selector: (row) => row.PackagePending,
      sortable: true,
    },
    {
      name: "Reason",
      cell: (row) => {
        return (
          <div>{row?.reason === "Other" ? row?.reasonOther : row?.reason}</div>
        );
      },
      grow: 2,
    },
    {
      name: "Returned",
      cell: (row) => {
        return <div>{row?.rtPackages ? row?.rtPackages : ""}</div>;
      },
      grow: 2,
    },
    {
      name: "App Cleared",
      selector: (row) => row.appCleared,
      sortable: true,
    },
    {
      name: "Notes",
      selector: (row) => row.notes,
      sortable: true,
      grow: 3,
    },
  ];

  /* Theme Setting */
  const darkTheme = {
    title: {
      fontSize: "22px",
      fontColor: "#FFFFFF",
      backgroundColor: "#363640",
    },
    contextMenu: {
      backgroundColor: "#E91E63",
      fontColor: "#FFFFFF",
    },
    header: {
      fontSize: "12px",
      fontColor: "#FFFFFF",
      backgroundColor: "#363640",
    },
    rows: {
      fontColor: "#FFFFFF",
      backgroundColor: "#363640",
      borderColor: "rgba(255, 255, 255, .12)",
      hoverFontColor: "black",
      hoverBackgroundColor: "rgba(0, 0, 0, .24)",
    },
    cells: {
      cellPadding: "10px",
    },
  };

  return (
    <>
      {/* <div className="overflow-x-auto overflow-y-hidden w-full border-2 border-gray-200 shadow-md rounded-lg">
        <table
          className="table w-full"
          style={{
            "::WebkitScrollbar": {
              display: "block !important",
              background: "#888",
            },
          }}
        >
          <thead>
            <tr>
              <th></th>
              <th>Status</th>
              <th>Date</th>
              <th>Driver Name</th>
              <th>Vehicle</th>
              <th>Vehicle Number</th>
              <th>Fuel Type</th>
              <th>Customer</th>
              <th>Branch</th>
              <th>Shift</th>
              <th>Start Time</th>
              <th>End Time</th>
              <th>Packages Taken</th>
              <th>Parcels Returned</th>
              <th>Reason</th>
              <th>Customer App Cleared</th>
              <th>Notes</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {packageData.map((data, index) => (
              <TableContent
                data={data}
                status={data.IsDelivered ? "Completed" : "Pending"}
                index={index}
                key={index}
              />
            ))}
          </tbody>
        </table>
      </div> */}
      <section className="w-full flex justify-center mt-4">
        <div className="w-full overflow-x-autoblock border border-gray-300">
          <h3 className="text-center text-xl font-semibold mt-6 mb-4">
            Reports
          </h3>
          {/* <div className="flex justify-center space-x-6 border-b mt-4">
            <button
              className={`py-2 border-b-4 transition-colors duration-300 border-teal-500`}
              onClick={(e) => {
                e.preventDefault();
                downloadXLSX(filteredItems);
              }}
            >
              Download Report
            </button>
          </div> */}
          <DataTable
            columns={columns}
            data={filteredItems}
            pagination
            paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            persistTableHead
            customTheme={darkTheme}
          />
        </div>
      </section>
    </>
  );
};

export default ReportTable;
